import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Modal from "react-bootstrap/Modal";
import "./QuickInquiry.css"
import Button_Common from "../../Button_Common/Button_Common"
import Submit_button from "../../Button_Common/Submit_button"
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import Select from "react-select";
import { productList } from "../../../../utils/apis/Product/Product";
const QuickInquiry = (props) => {
    const [modalShow, setModalShow] = React.useState(false);

    const { getData, IMG_URL, Select2Data, postData } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        watch,
        trigger,
        setValue
    } = useForm();

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("name", data?.name);
        formData.append("email", data?.email);
        formData.append("contact_no", data?.contact_no);
        formData.append("subject", data?.subject);
        formData.append("product_id", data?.product_id?.value);
        const res = await postData("/inquiry/save", formData)
        if (res?.success) {
            setModalShow(true);
            reset();
            props.onHide()
        } else {
            console.error('Error Submitting Form :', res?.error);
        }
    }

    const [products, setProducts] = useState([]);

    const getProducts = async () => {
        const res = await productList();
        if (res?.success) {
            const data = await Select2Data(res?.data?.data, 'product_id');
            setProducts(data);
        }
    }

    useEffect(() => {
        getProducts();
    }, [])

    return (
        <>
            <section className="QuickInquiry">
                <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="QuickInquiry">

                    <div className="Modal-holder">
                        <div className="log_in_form">
                            <div className="login-box-button">
                                <p>Quick inquiry</p>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)}>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className="sub-title">Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Enter Name"
                                        {...register("name", {
                                            required: "Name is required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.name,
                                        })}
                                        onKeyDown={(event) => {
                                            if (!/[A-Z-a-z ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                                </Form.Group>

                                <Row>
                                    <Col xxl={6} xl={6} lg={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="sub-title">Email*</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                placeholder="email"
                                                className={classNames("", {
                                                    "is-invalid": errors?.email,
                                                })}
                                                {...register("email", {
                                                    required: "Email is required",
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                        message: "Invalid email address",
                                                    },
                                                    validate: (value) => {
                                                        // Extract the domain and TLD
                                                        const domainPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/;
                                                        const match = value.match(domainPattern);
                                                        if (match) {
                                                            const domainParts = match[1].split('.');
                                                            const tld = match[2];

                                                            // Ensure the domain and TLD are not the same
                                                            if (domainParts[domainParts.length - 1] === tld) {
                                                                return "Domain and top-level domain must be different";
                                                            }
                                                        }
                                                        return true;
                                                    }
                                                })}
                                            />

                                            {errors.email && (
                                                <span className="text-danger">
                                                    {errors.email.message}
                                                </span>
                                            )}

                                        </Form.Group>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="sub-title">Phone No.*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="contact_no"
                                                placeholder="Contact No."
                                                {...register("contact_no", {
                                                    required: "Contact number is required",
                                                    validate: {
                                                        isTenDigits: (value) =>
                                                            value && value.length === 10 || "Contact number must be 10 digits",
                                                        isNumeric: (value) =>
                                                            /^\d+$/.test(value) || "Contact number must be numeric"
                                                    }
                                                })}
                                                className={classNames("", {
                                                    "is-invalid": errors?.contact_no,
                                                    "is-valid": !errors?.contact_no && getValues("contact_no")?.length === 10,
                                                })}
                                                onKeyDown={(event) => {
                                                    const { key } = event;
                                                    if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                                                        event.preventDefault();
                                                    }
                                                    if (event.target.value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            {errors.contact_no && (
                                                <span className="text-danger">
                                                    {errors.contact_no.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className="sub-title">Product *</Form.Label>
                                    <Controller
                                        name="product_id" // name of the field
                                        {...register("product_id", {
                                            required: "Select Product",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.product_id ? "red" : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={products}
                                            />
                                        )}
                                    />

                                    {errors.product_id && (
                                        <span className="text-danger">
                                            {errors.product_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                                <p>Subject*</p>
                                <FloatingLabel controlId="floatingTextarea2" label="">
                                    <Controller
                                        name="subject"
                                        control={control}
                                        rules={{ required: "Subject is required" }}
                                        render={({ field }) => (
                                            // <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Leave a comment here"
                                                style={{ height: "100px" }}
                                                {...field}
                                            />
                                            // </FloatingLabel>
                                        )}
                                    />
                                </FloatingLabel>

                                <div className="Button-Main mt-3">

                                    <Button_Common text="Cancel" className="cancel" onClick={() => props.onHide()} />
                                    <Submit_button text="Submit" className="submit orange" />
                                </div>
                            </Form>

                        </div>
                    </div>

                </Modal>
                <Successfull_Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    message="Inquiry Send Successfully!"
                />
            </section>
        </>
    );
};

export default QuickInquiry;
