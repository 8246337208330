import React, { useContext, useEffect, useState } from "react";
import "./Categorytable.css";
import { Container, Form, Table } from "react-bootstrap";
import {
  faChevronLeft,
  faChevronRight,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { getBulkOrderProductsCategory } from "../../../utils/apis/bulkorder";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import {
  AddToCart,
  CartQuantity,
  getCartCheck,
} from "../../../utils/apis/addToCart/addToCart";
import { UserType } from "../../../utils/common";
import Warning_Modal from "../../CommonComponent/Warning_Modal/Warning_Modal";
import Quantity_discount_modal from "../../CommonComponent/Common_Modal/Quantity_discount_modal/Quantity_discount_modal";

const Counter = ({ data }) => {
  const [count, setCount] = useState(1);
  const { IMG_URL, htmlToReactParser, setCart, signin, usertype } = useContext(
    Context
  );

  const navigate = useNavigate();
  const [cart, setCartItem] = useState({});
  const [status, setStatus] = useState(false);

  const handleAddToCart = async () => {
    try {
      navigate(`/sample-roll/${data.product_id}`);
      // const temp = {
      //   name: data?.name,
      //   short_description: data?.short_description,
      //   product_id: data?.product_id,
      //   image: IMG_URL + data?.image,
      //   // variant_id: data.product_quantities.find(() => true)?.id,
      //   p_a_v_id: data?.p_a_v_id,
      //   p_a_v_a_v_id: data?.p_a_v_a_v_id,
      //   price: data?.btb_price,
      //   quantity: 1,
      //   // quantity: data?.min_quantity,
      //   // tax_percentage: data?.tax_percentage,
      //   status: 1,
      //   box_unit: data?.box_unit,
      //   type: "bulk",
      // };

      // const res = await AddToCart(temp);

      // if (res?.data) {
      //   setCount(res?.data?.quantity);
      //   setCartItem(res?.data);
      //   setStatus(true);
      // }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const getCheck = async () => {
    const item = {
      product_id: data?.product_id,
      p_a_v_a_v_id: data?.p_a_v_a_v_id || null,
      p_a_v_id: data?.p_a_v_id || null,
    };
    const res = await getCartCheck(item);
    if (res?.success) {
      setCartItem(res?.cartItem);
      setCount(res?.cartItem?.quantity);
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const increaseCount = async () => {
    const res = await CartQuantity(cart?.id, "add", signin, setCart);
    if (res) {
      getCheck();
    }
  };

  const decreaseCount = async () => {
    const res = await CartQuantity(cart?.id, "minus", signin, setCart);
    if (res) {
      getCheck();
    }
  };

  const RemoveFromCart = async () => {
    const res = await CartQuantity(cart?.id, "delete", signin, setCart);
    if (res) {
      getCheck();
    }
  };

  useEffect(() => {
    getCheck();
  }, [data]);

  const [warning, setWarning] = useState(false);
  const AlertPopUp = async () => {
    setWarning({
      show: true,
      onHide: () => setWarning(false),
      message: "Please Login as B2B Customer",
    });

    setTimeout(() => {
      setWarning(false);
    }, 2000);
  };

  return status ? (
    <>
      <span className="conutssdiv me-3">
        {!cart.moq_id && (
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="ficnn px-1"
            onClick={decreaseCount}
          />
        )}

        <span className="px-1">{cart?.quantity}</span>

        {!cart.moq_id && (
          <FontAwesomeIcon
            icon={faChevronRight}
            className="ficnn px-1"
            onClick={increaseCount}
          />
        )}
      </span>
      <FontAwesomeIcon onClick={RemoveFromCart} icon={faTrashCan} />
    </>
  ) : (
    <>
      <p
        onClick={() => {
          usertype == UserType.B2BCustomer ? handleAddToCart() : AlertPopUp();
        }}
      >
        Add
      </p>
      <Warning_Modal
        show={warning.show}
        onHide={warning.onHide}
        message={warning.message}
      />
    </>
  );
};

function Categorytable() {
  const [selectedCategory, setSelectedCategory] = useState("Brown Tape");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const [selectedOption, setSelectedOption] = useState("Transparent Tape");

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
    setIsOpen(!isOpen);
  };

  const options = [
    { key: "Transparent Tape", label: "Transparent Tape" },
    { key: "Brown Tape", label: "Brown Tape" },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const visibleOptions = options.filter(
    (option) => option.key !== selectedOption
  );

  const tabheading = [
    { name: "SKU" },
    // { name: "Name" },
    { name: "Width" },
    { name: "Length" },
    { name: "Thickness" },
    { name: "Colour" },
    { name: "Per Box Units" },
    { name: "Weight" },
    { name: "Availability" },
    { name: "B2B Price" },
    { name: "B2C Price" },
    { name: "MRP Price" },
    { name: "Add to Cart" },
  ];

  const tabledata = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "In stock",
      price: "$50.95",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "out of stock",
      price: "$50.95",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "In stock",
      price: "$50.95",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "out of stock",
      price: "$50.95",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "In stock",
      price: "$50.95",
    },
  ];
  const tabledata2 = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "out of stock",
      price: "$50.95",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "In stock",
      price: "$50.95",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "out of stock",
      price: "$50.95",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "In stock",
      price: "$50.95",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
      name: "BRN-Single-1-40-144",
      width: "1/2 ",
      length: "60 yds",
      thickness: "5.6 mil",
      colours: "Crepe",
      units: "72",
      weights: "13 Ibs",
      stock: "out of stock",
      price: "$50.95",
    },
  ];

  // ---------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser, usertype, signin, setCart } = useContext(
    Context
  );
  const [data, setData] = useState([]);
  const [active, setActive] = useState(null);

  const getData = async (id = null) => {
    const res = await getBulkOrderProductsCategory(id);
    if (res?.success) {
      setData(res?.data);
    }
    if (id) {
      setActive(id);
    } else {
      const defaultCategoryId =
        res?.data?.data?.category_id || res?.data?.categoryNames?.[0]?.id;
      setActive(defaultCategoryId);
    }
  };

  const [cartStatus, setCartStatus] = useState({});
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const checkCartStatus = async () => {
  //     setLoading(true);
  //     const statuses = {};

  //     for (let variant of data?.data) {
  //       const item = {
  //         product_id: variant?.product_id,
  //         p_a_v_a_v_id: variant?.p_a_v_a_v_id || null,
  //         p_a_v_id: variant?.p_a_v_id || null,
  //       }
  //       const result = await getCartCheck(item);
  //       statuses[variant.id] = result.success;
  //     }

  //     setCartStatus(statuses);
  //     setLoading(false);
  //   };

  //   checkCartStatus();
  // }, [data]);

  useEffect(() => {
    getData();
  }, []);

  const [warning, setWarning] = useState(false);
  const AlertPopUp = async () => {
    setWarning({
      show: true,
      onHide: () => setWarning(false),
      message: "Please Login as B2B Customer",
    });

    setTimeout(() => {
      setWarning(false);
    }, 2000);
  };

  const [modalShow5, setModalShow5] = React.useState(false);

  return (
    <section className="cattable_section">
      <Container>
        <div className="cattable_div">
          <div className="dropdiv mb-3">
            <div>
              {/* <p className='text-p mb-0 pe-4'>Category</p>
                        <Form.Select className='text-p formmselect' aria-label="Default select example" value={selectedCategory} onChange={handleCategoryChange}>
                            <option className='text-p' value="Brown Tape">Brown Tape</option>
                            <option className='text-p' value="Transparent Tape">Transparent Tape</option>
                        </Form.Select>
                        */}
              {/* <p className="text-p mb-0">Category</p>
                                <Dropdown onSelect={handleSelect}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="text-p">
                                        {selectedOption}
                                        <span className={`arrow-icon ${isOpen ? 'open' : ''}`}></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {visibleOptions.map((option) => (
                                            <Dropdown.Item eventKey={option.key} key={option.key} className="text-p">
                                                {option.label}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown> */}

              {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                  <Nav.Link eventKey="first">Category 1</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Category 2</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  {" "}
                  {selectedCategory === "Brown Tape" && (
                    <div className="cattable_main">
                      <Table responsive className="tablees text-center">
                        <thead>
                          <tr>
                            {tabheading.map((heading, index) => (
                              <th
                                className={`headdingss ${
                                  index === 0 ? "first-heading" : ""
                                } ${
                                  index === tabheading.length - 1
                                    ? "last-heading"
                                    : ""
                                }`}
                                key={index}
                              >
                                {heading.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {tabledata.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <div className="imggdiv">
                                  <img
                                    src={data.image}
                                    className="tapeimgg"
                                    alt="Product"
                                  />
                                  <p className="smalltexxx mb-0">{data.name}</p>
                                </div>
                              </td>
                              <td className="bottomtd commmontext">
                                {data.width}"
                                <span className="smalltexxx"> inches</span>
                              </td>
                              <td className="bottomtd commmontext">
                                {data.length}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.thickness}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.colours}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.units}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.weights}
                              </td>
                              <td
                                className={`bottomtd commontext ${
                                  data.stock === "In stock"
                                    ? "greencol"
                                    : data.stock === "out of stock"
                                    ? "redcol"
                                    : ""
                                }`}
                              >
                                {data.stock}
                              </td>
                              <td className="bottomtd commmontext pricetttxt">
                                {data.price}
                              </td>
                              <td className="bottomtd commmontext">
                                <div>
                                  <Counter />
                                  Add
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  {" "}
                  {selectedCategory === "Transparent Tape" && (
                    <div className="cattable_main">
                      <Table responsive className="tablees text-center">
                        <thead>
                          <tr>
                            {tabheading.map((heading, index) => (
                              <th
                                className={`headdingss ${
                                  index === 0 ? "first-heading" : ""
                                } ${
                                  index === tabheading.length - 1
                                    ? "last-heading"
                                    : ""
                                }`}
                                key={index}
                              >
                                {heading.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {tabledata2.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <div className="imggdiv">
                                  <img
                                    src={data.image}
                                    className="tapeimgg"
                                    alt="Product"
                                  />
                                  <p className="smalltexxx mb-0">{data.name}</p>
                                </div>
                              </td>
                              <td className="bottomtd commmontext">
                                {data.width}"
                                <span className="smalltexxx"> inches</span>
                              </td>
                              <td className="bottomtd commmontext">
                                {data.length}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.thickness}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.colours}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.units}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.weights}
                              </td>
                              <td
                                className={`bottomtd commontext ${
                                  data.stock === "In stock"
                                    ? "greencol"
                                    : data.stock === "out of stock"
                                    ? "redcol"
                                    : ""
                                }`}
                              >
                                {data.stock}
                              </td>

                              <td className="bottomtd commmontext pricetttxt">
                                {data.price}
                              </td>
                              <td className="bottomtd commmontext">
                                <div>
                                  <Counter />
                                  Add
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container> */}
            </div>

            <Tab.Container id="left-tabs-example" activeKey={active}>
              <Nav variant="pills" className="justify-content-center">
                {data?.categoryNames?.map((category, index) => (
                  <Nav.Item
                    key={category?.id}
                    onClick={() => getData(category?.id)}
                  >
                    <Nav.Link eventKey={category?.id}>
                      {category?.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
                {/* <Nav.Item>
                  <Nav.Link eventKey="first">Category 1</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Category 2</Nav.Link>
                </Nav.Item> */}
              </Nav>

              <Tab.Content>
                {data?.categoryNames?.map((category, index) => (
                  <Tab.Pane eventKey={category?.id}>
                    {" "}
                    {/* {selectedCategory === "Brown Tape" && ( */}
                    <div className="cattable_main">
                      <Table responsive className="table text-center">
                        <thead>
                          <tr>
                            {tabheading.map((heading, index) => (
                              <th
                                className={`headdingss ${
                                  index === 0 ? "first-heading" : ""
                                } ${
                                  index === tabheading.length - 1
                                    ? "last-heading"
                                    : ""
                                }`}
                                key={index}
                              >
                                {heading.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.map((data, index) => (
                            <tr key={index}>
                              <td
                                onClick={() => {
                                  navigate(`/sample-roll/${data.product_id}`);
                                  // usertype == UserType.B2BCustomer
                                  //   ? navigate(
                                  //       `/sample-roll/${data.product_id}`
                                  //     )
                                  //   : AlertPopUp();
                                }}
                              >
                                <div className="imggdiv">
                                  <img
                                    src={IMG_URL + data?.image}
                                    className="tapeimgg"
                                    alt="Product"
                                  />
                                  <p className="smalltexxx mb-0">
                                    {data?.sku_id}
                                  </p>
                                </div>
                              </td>
                              {/* <td className="bottomtd commmontext">
                                {data?.width}"
                                <span className="smalltexxx"> inches</span>
                              </td> */}
                              {/* <td
                                className="bottomtd commmontext"
                                onClick={() => {
                                  usertype == UserType.B2BCustomer
                                    ? navigate(
                                        `/sample-roll/${data.product_id}`
                                      )
                                    : AlertPopUp();
                                }}
                              >
                                {data?.name}
                              </td> */}
                              <td className="bottomtd commmontext">
                                {data?.width}
                              </td>
                              <td className="bottomtd commmontext">
                                {data?.length}
                              </td>
                              <td className="bottomtd commmontext">
                                {data?.thickness}
                              </td>
                              <td className="bottomtd commmontext">
                                {data?.color}
                              </td>
                              <td className="bottomtd commmontext">
                                {data?.box_unit}
                              </td>
                              <td className="bottomtd commmontext">
                                {data?.weight}
                              </td>
                              <td
                                // className={`bottomtd commontext ${
                                //   data?.stock === "In stock"
                                //     ? "greencol"
                                //     : data?.stock === "out of stock"
                                //     ? "redcol"
                                //     : ""
                                // }`}

                                className={`bottomtd commontext`}
                              >
                                {data?.stock}
                              </td>
                              {/* pricetttxt pricetttxt pricetttxt */}
                              <td className="bottomtd commmontext ">
                                {data?.btb_price}
                              </td>
                              <td className="bottomtd commmontext ">
                                {data?.btc_price}
                              </td>
                              <td className="bottomtd commmontext ">
                                {data?.mrp}
                                <br></br>
                                <span
                                  type="button"
                                  onClick={() =>
                                    setModalShow5(data?.product_id)
                                  }
                                >
                                  {" "}
                                  Quantity Discount
                                </span>
                              </td>
                              <td className="bottomtd commmontext">
                                <div>
                                  {/* {loading ? (
                                    <span>Loading...</span>
                                  ) : cartStatus[data.id] ? ( */}
                                  <Counter data={data} />
                                  {/* ) : (

                                    <p onClick={() => handleAddToCart(data)}>Add</p>
                                  )} */}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    {/* )} */}
                  </Tab.Pane>
                ))}
                {/* <Tab.Pane eventKey="second">
                  {" "}
                  {selectedCategory === "Transparent Tape" && (
                    <div className="cattable_main">
                      <Table responsive className="tablees text-center">
                        <thead>
                          <tr>
                            {tabheading.map((heading, index) => (
                              <th
                                className={`headdingss ${index === 0 ? "first-heading" : ""
                                  } ${index === tabheading.length - 1
                                    ? "last-heading"
                                    : ""
                                  }`}
                                key={index}
                              >
                                {heading.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {tabledata2.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <div className="imggdiv">
                                  <img
                                    src={data.image}
                                    className="tapeimgg"
                                    alt="Product"
                                  />
                                  <p className="smalltexxx mb-0">{data.name}</p>
                                </div>
                              </td>
                              <td className="bottomtd commmontext">
                                {data.width}"
                                <span className="smalltexxx"> inches</span>
                              </td>
                              <td className="bottomtd commmontext">
                                {data.length}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.thickness}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.colours}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.units}
                              </td>
                              <td className="bottomtd commmontext">
                                {data.weights}
                              </td>
                              <td
                                className={`bottomtd commontext ${data.stock === "In stock"
                                    ? "greencol"
                                    : data.stock === "out of stock"
                                      ? "redcol"
                                      : ""
                                  }`}
                              >
                                {data.stock}
                              </td>

                              <td className="bottomtd commmontext pricetttxt">
                                {data.price}
                              </td>
                              <td className="bottomtd commmontext">
                                <div>
                                  <Counter />
                                  Add
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </Tab.Pane> */}
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </Container>

      <Quantity_discount_modal
        show={modalShow5}
        onHide={() => setModalShow5(false)}
      />

      <Warning_Modal
        show={warning.show}
        onHide={warning.onHide}
        message={warning.message}
      />
    </section>
  );
}

export default Categorytable;
