import React, { useEffect, useState } from "react";
import ProductDetail from "./ProductDetail/ProductDetail";
import { Container } from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import TopPicks from "./TopPicks/TopPicks";
import Categorytable from "./CategoryTable/Categorytable";

function B2BBulkOrder() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "B2B BULK ORDER", link: "" },
    ,
  ]);
  return (
    <>
      <Container>
        <div>
          <Breadcrumbs items={breadcrumbItems} />
        </div>
      </Container>
      {/* <ProductDetail /> */}

      <Categorytable />
      <TopPicks />
    </>
  );
}

export default B2BBulkOrder;
