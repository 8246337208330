import React, { useContext, useEffect, useState } from "react";
import "./Register.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import Successfull_Modal from "../../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
import { useForm } from "react-hook-form";
import { Context } from "../../../utils/context";
import { Register } from "../../../utils/apis/login/Login";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import { RegxExpression, UserType } from "../../../utils/common";
import {
  MobileOTPSend,
  MobileOTPVerify,
  NewMobileOTPSend,
} from "../../../utils/apis/VerifyOTP/VerifyOTP";
const BTC_Register = () => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("first");
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  useEffect(() => {
    if (modalShow) {
      const timer = setTimeout(() => {
        setModalShow(false);
        navigate("/login");
      }, 3000); // Navigate after 3 seconds

      // Clean up timer if component unmounts or modalShow changes
      return () => clearTimeout(timer);
    }
  }, [modalShow, navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  //   ------------------------------------------------------------------------------------------------
  const { signin, setSignin, setUsertype, usertype, Cookies } = useContext(
    Context
  );
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      type: UserType.B2CCustomer,
      verifed: false,
    },
  });

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const onSubmit = async (data) => {
    // console.log(data);

    if (password === passwordAgain) {
      //   if (getValues("verifed")) {
      const res = await Register(data);
      // console.log(res);
      if (res?.success) {
        // setValue("verifed", false);
        // setIsVerified(false);
        await Cookies.set("cmj_security", res?.data?.token);
        reset();
        clearErrors();
        setSignin(true);
        setModalShow(true);
        navigate("/");
        // setUsertype(res?.data?.type);
      } else {
        if (res?.message?.both) {
          setError("email", { message: res?.message?.both });
          setError("contact_no", { message: "" });
        }
        if (res?.message?.email) {
          setError("email", { message: res?.message?.email });
        }
        if (res?.message?.contact_no) {
          setError("contact_no", { message: res?.message?.contact_no });
        }
      }
      //   } else {
      //     setError("contact_no", { message: "Mobile No Not Verifyed" });
      //   }
    } else {
      setError("re_password", { message: "Password Does not match" });
    }
  };

  //   const [isVerified, setIsVerified] = useState(false);
  //   const [disabled, setDisabled] = useState(false);

  //   const handleSendOTP = async () => {
  //     if (getValues("contact_no").length == 10) {
  //       clearErrors("contact_no");
  //       const res = await NewMobileOTPSend({
  //         contact_no: getValues("contact_no"),
  //       });
  //       console.log(res);
  //       if (res.success) {
  //         setIsVerified(true);
  //         setDisabled(true);
  //         setIsResendEnabled(false);
  //         setTimer(120);
  //       } else {
  //         setError("contact_no", { message: res.message });
  //       }
  //     } else {
  //       setError("contact_no", { message: "Enter Valid Mobile No." });
  //     }
  //   };

  //   const handleOTPVerify = async () => {
  //     if (getValues("otp").length == 4) {
  //       clearErrors("otp");
  //       const res = await MobileOTPVerify({
  //         contact_no: getValues("contact_no"),
  //         otp: getValues("otp"),
  //       });
  //       console.log(res);
  //       if (res.success) {
  //         // setIsVerified(false);
  //         setValue("verifed", true);
  //         setOtpsuccessModalShow(true);
  //         // setIsVerified(false);
  //       } else {
  //         setError("otp", { message: res.message });
  //       }
  //     } else {
  //       setError("otp", { message: "Enter Valid OTP" });
  //     }

  //     // Simulate verification
  //   };

  //   const deletOtp = async () => {
  //     if (getValues("contact_no")) {
  //       let sanitizedContactNo = getValues("contact_no").replace(/^(\+91)?/, "");
  //       // Remove any dashes (-)
  //       sanitizedContactNo = sanitizedContactNo.replace(/-/g, "");
  //       const res = await postData(`/without-login/verify-otp/delete`, {
  //         contact_no: sanitizedContactNo,
  //       });
  //       if (res?.success) {
  //         console.log("OTP DELETED", res?.data);
  //       } else {
  //         console.error("Failed to delete OTP:", res.message);
  //       }
  //     } else {
  //       console.error("Valid Contact number is required");
  //       // setContactError("Contact number is required");
  //     }
  //   };

  //   useEffect(() => {
  //     reset();
  //     setValue("verifed", false);
  //     setIsVerified(false);
  //     setDisabled(false);
  //   }, []);

  return (
    <>
      <div className="b2c-customer form-field-sec-holder">
        <Form className="second-main">
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label className="small-text">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  {...register("first_name", {
                    required: "First name required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.first_name,
                    // "is-valid": getValues("first_name"),
                  })}
                  onKeyDown={(event) => {
                    if (!RegxExpression.name.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label className="small-text">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  {...register("last_name", {
                    required: "Last name required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.last_name,
                    // "is-valid": getValues("last_name"),
                  })}
                  onKeyDown={(event) => {
                    if (!RegxExpression.name.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label className="small-text">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: RegxExpression.email,
                      message: "Invalid email address",
                    },
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.email,
                    // "is-valid": getValues("email"),
                  })}
                />
                {errors?.email && (
                  <sup className="text-danger">{errors?.email?.message}</sup>
                )}
              </Form.Group>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label className="small-text">Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  className={classNames("", {
                    "is-invalid": errors?.contact_no,
                    // "is-valid": isVerified,
                  })}
                  //   disabled={disabled}
                  name="contact_no"
                  placeholder="Enter Mobile Number"
                  {...register("contact_no", {
                    required: "Mobile Number is required",
                    minLength: {
                      value: 10,
                      message: "Number should be at least 10 characters",
                    },
                  })}
                  onKeyDown={(event) => {
                    if (!RegxExpression.phone.test(event.key)) {
                      if (event.key !== "Backspace") {
                        event.preventDefault();
                      }
                    }
                  }}
                  maxLength={10}
                />
                {errors?.contact_no && (
                  <sup className="text-danger">
                    {errors?.contact_no?.message}
                  </sup>
                )}
              </Form.Group>{" "}
              {/* {!isVerified && (
                <div className="verify-text-holder" onClick={handleSendOTP}>
                  <span>Send OTP</span>
                </div>
              )} */}
            </Col>
          </Row>

          {/* {isVerified && (
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="verify-field-holder">
                <Form.Group className="mb-2 field-holder" controlId="">
                  <Form.Control
                    type="text"
                    className={classNames("", {
                      "is-invalid": errors?.otp,
                      // "is-valid": getValues("verifed"),
                    })}
                    name="otp"
                    disabled={getValues("verifed")}
                    placeholder="Enter OTP"
                    {...register("otp", {
                      required: "Field is required",
                      minLength: {
                        value: 4,
                        message: "Number should be at least 4 characters",
                      },
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.phone.test(event.key)) {
                        if (event.key !== "Backspace") {
                          event.preventDefault();
                        }
                      }
                    }}
                    maxLength={4}
                  />
                </Form.Group>{" "}
                <div className="otp-timer ">
                  {isResendEnabled
                    ? !getValues("verifed") && (
                        <span
                          className="text-green-holder"
                          onClick={handleSendOTP}
                        >
                          Resend OTP
                        </span>
                      )
                    : !getValues("verifed") && (
                        <span>Enter OTP in {timer} seconds</span>
                      )}
                </div>
                {!getValues("verifed") && (
                  <div
                    className="verify-text-holder"
                    // onClick={() => {
                    //   setOtpsuccessModalShow(true);
                    // }}
                    onClick={handleOTPVerify}
                  >
                    <span>Verify OTP</span>
                  </div>
                )}
              </div>
              {errors?.otp && (
                <sup className="text-danger">{errors?.otp?.message}</sup>
              )}
            </div>
          )} */}
          <Row>
            <Col xxl={6} xl={6} lg={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label className="small-text">Password</Form.Label>
                <div className="password-field-holder">
                  <Form.Control
                    // type={passwordVisible ? "text" : "password"}
                    // placeholder="Password"

                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter Password*"
                    name="password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message:
                          "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.password,
                      //   "is-valid": getValues("password"),
                    })}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col xxl={6} xl={6} lg={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label className="small-text">Confirm Password</Form.Label>
                <div className="password-field-holder">
                  <Form.Control
                    // type={confirmPasswordVisible ? "text" : "password"}
                    // placeholder="Confirm Password"
                    type={confirmPasswordVisible ? "text" : "password"}
                    placeholder="Re-Enter Password*"
                    name="re_password"
                    {...register("re_password", {
                      required: "Re-Password is required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.re_password,
                      //   "is-valid": getValues("re_password"),
                    })}
                    onChange={(e) => setPasswordAgain(e.target.value)}
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={confirmPasswordVisible ? faEye : faEyeSlash}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          {password && (
            <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={8}
              value={password}
              valueAgain={passwordAgain}
              onChange={(isValid) => {}}
              style={{
                fontSize: "10px",
                padding: "4px",
                margin: "0",
              }}
            />
          )}
        </Form>
        <div className="create">
          <Button_Common
            // onClick={() => setModalShow(true)}
            onClick={handleSubmit(onSubmit)}
            className=" button button--webcoderskull button--border-thick button--text-medium login-btn sub-title create orange"
            text="Create"
          />
        </div>

        <div className="create_account">
          <div className="login_text">
            <p>
              <Link to={"/register"}>
                <span className="sub-text">
                  Already have an account?{" "}
                  <Link to={"/login"}>
                    <span className="sub-text open">Open account</span>
                  </Link>
                </span>
              </Link>
            </p>
          </div>
        </div>

        {/* <div className="log_in_with">
          <p>or Login with social accounts</p>
        </div> */}

        {/* <div className="social_img_holder">
          <img
            className="social_img"
            src={process.env.PUBLIC_URL + "/assests/login/google-img.png"}
            alt="social_img"
          />
        </div> */}
        <Link to="/">
          <div className="back_to_span">
            <p>Back to Home {">"}</p>
          </div>
        </Link>
      </div>

      <Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message="Account Created Successfully!"
      />
    </>
  );
};

export default BTC_Register;
