import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import { SeletedAddress } from "../../utils/apis/address";
import { getSeletedCart } from "../../utils/apis/addToCart/addToCart";
import { payment_options } from "../../utils/common";
const PaymentOption = ({ handlePrevious, handleNext, ...props }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ----------------------------------------------------------------
  const [Address, SetAddress] = useState({});

  const [isCod, setIsCod] = useState(0);
  const getData = async () => {
    const res = await SeletedAddress();
    SetAddress(res.data);
    props.setValue("address_id", res.data.id);
    props.setValue("address", res.data);

    {
      const res = await getSeletedCart();
      setIsCod(res.data?.is_cod);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const SaveNext = async () => {
    // console.log(props.getValues("payment_mode"));
    if (props.getValues("payment_mode")) {
      handleNext();
    }
  };

  return (
    <>
      <section className="checkout-inner-steps">
        <div className="">
          <div className="adresmain">
            {/* <h3>Thank You for Trusting Us</h3> */}
          </div>
          <Row>
            <Col xxl={8} xl={8} lg={8}>
              <div className="Choose_Payment_Option">
                <div className="heading-holder">
                  <h4 className="price-text">Choose Payment Option</h4>
                </div>
                <div>
                  <Form>
                    <div className="radio-btn-input-holder">
                      {isCod ? (
                        <Form.Check
                          type="radio"
                          name="payment_mode"
                          {...props.register("payment_mode", {
                            required: "payment type is required",
                          })}
                          value="cod"
                          label="Cash on Delivery"
                        />
                      ) : (
                        <></>
                      )}

                      <Form.Check
                        type="radio"
                        name="payment_mode"
                        {...props.register("payment_mode", {
                          required: "payment type is required",
                        })}
                        value="online"
                        label="Online"
                      />
                    </div>
                  </Form>
                </div>{" "}
                {/* Customer Type  */}
                <div className="heading-holder mt-4">
                  <h4 className="price-text">Customer Type (Optional)</h4>
                </div>
                <div>
                  <Form>
                    <div className="radio-btn-input-holder">
                      <Form.Check
                        type="radio"
                        name="customer_type"
                        {...props.register("customer_type", {
                          required: "Customer Type is required",
                        })}
                        value="End User"
                        label="End User"
                      />
                      <Form.Check
                        type="radio"
                        name="customer_type"
                        {...props.register("customer_type", {
                          required: "Customer Type is required",
                        })}
                        value="Reseller"
                        label="Reseller"
                      />
                    </div>
                  </Form>
                </div>{" "}
                {/* Customer Type  */}
                {/* Repeat  */}
                <div className="heading-holder mt-4">
                  <h4 className="price-text">Repeat ? (Optional)</h4>
                </div>
                <div>
                  <Form>
                    <div className="radio-btn-input-holder">
                      <Form.Check
                        type="radio"
                        name="repeat"
                        {...props.register("repeat", {
                          // required: "Repeat is required",
                        })}
                        onChange={(e) => {
                          props.setValue("repeat", e.target.value);
                          props.setValue("nxt_after_days", null);
                          props.reset(props.getValues());
                        }}
                        value={payment_options.Repeat.SingleTime}
                        label={payment_options.Repeat.SingleTime}
                      />
                      <Form.Check
                        type="radio"
                        name="repeat"
                        {...props.register("repeat", {
                          // required: "Repeat is required",
                        })}
                        onChange={(e) => {
                          props.setValue("repeat", e.target.value);
                          props.reset(props.getValues());
                        }}
                        value={payment_options.Repeat.RepeatedTime}
                        label={payment_options.Repeat.RepeatedTime}
                      />
                      <Form.Check
                        type="radio"
                        name="repeat"
                        {...props.register("repeat", {
                          // required: "Repeat is required",
                        })}
                        onChange={(e) => {
                          props.setValue("repeat", e.target.value);
                          props.setValue("nxt_after_days", null);

                          props.reset(props.getValues());
                        }}
                        value={payment_options.Repeat.NotSure}
                        label={payment_options.Repeat.NotSure}
                      />
                    </div>
                  </Form>
                </div>{" "}
                {/* Repeat  */}
                {/* Next Order After How Many Days
                 */}
                {props.getValues("repeat") ==
                  payment_options.Repeat.RepeatedTime && (
                  <>
                    <div className="heading-holder mt-4">
                      <h4 className="price-text">
                        Next Order After How Many Days ?
                      </h4>
                    </div>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="nxt_after_days"
                          placeholder="Next Order After How Many Days ?"
                          maxLength={40}
                          {...props.register("nxt_after_days", {
                            required:
                              "Next Order After How Many Days is required",
                            min: {
                              value: 1,
                              message: "Minimum value is 1",
                            },
                            pattern: {
                              value: /^[1-9][0-9]*$/,
                              message: "Please enter a valid number",
                            },
                          })}
                        />
                      </InputGroup>
                    </Form.Group>
                  </>
                )}
                {/* Next Order After How Many Days
                 */}
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <div className="Primary-Contact">
                <p className="sub-title">Primary Contact</p>
                <p className="sub-text ">
                  {" "}
                  {Address?.user?.first_name} {Address?.user?.last_name}
                </p>
                <p className="sub-text">
                  {`${Address?.add_type} ${Address?.address} ${Address?.landmark} - ${Address?.zip_code}`}
                </p>
              </div>
              <div className="  apply_now_btn">
                <Button_Common
                  onClick={handlePrevious}
                  className="back-btn me-3 small-text back"
                  text="BACK"
                >
                  Back
                </Button_Common>

                <Button_Common
                  type="button"
                  onClick={SaveNext}
                  //   onClick={handleNext}
                  className="tabs-btn small-text orange"
                  text="NEXT"
                >
                  Next
                </Button_Common>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default PaymentOption;
