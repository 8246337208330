import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import "./ContactUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import Successfull_Modal from "../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import { AppSetup, postContactUs } from "../../utils/apis/common/Common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Contact us", link: "", className: "active" },
  ]);
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  const handleModalClose = () => {
    setModalShow(false);
    navigate("/");
  };

  const [cardsDetails, setcardsDetails] = useState([
    {
      title: "+91 3454564578",
      image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/truck.png",
      icon: faPhone,
    },
    {
      title: "Cmj@gmail.com",
      image:
        process.env.PUBLIC_URL +
        "/assests/HomePage/ChooseUs/technical-support.png",
      icon: faEnvelope,
    },
    {
      title: "Akshaya Nagar 1st Block,1st Cross, Patil Nagar, Pune",
      image:
        process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/locked-card.png",
      icon: faLocationDot,
    },
  ]);

  //   const cardsDetails = [
  //     {
  //       title: "+91 3454564578",
  //       image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/truck.png",
  //       icon: faPhone,
  //     },
  //     {
  //       title: "Cmj@gmail.com",
  //       image:
  //         process.env.PUBLIC_URL +
  //         "/assests/HomePage/ChooseUs/technical-support.png",
  //       icon: faEnvelope,
  //     },
  //     {
  //       title: "Akshaya Nagar 1st Block,1st Cross, Patil Nagar, Pune",
  //       image:
  //         process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/locked-card.png",
  //       icon: faLocationDot,
  //     },
  //   ];

  // ----------------------------------------------------------------
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await AppSetup();

    setcardsDetails([
      {
        title: res?.data?.contact_no,
        image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/truck.png",
        icon: faPhone,
      },
      {
        title: res?.data?.email,
        image:
          process.env.PUBLIC_URL +
          "/assests/HomePage/ChooseUs/technical-support.png",
        icon: faEnvelope,
      },
      {
        title: res?.data?.address,
        image:
          process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/locked-card.png",
        icon: faLocationDot,
      },
    ]);
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  //   ----------------------------------------------------------------

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await postContactUs(data);
    if (res?.success) {
      setModalShow(true);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  return (
    <>
      <section className="ContactUs">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="chooseus_main_div">
            <Row className="justify-content-center">
              {cardsDetails.map((item, index) => (
                <Col key={index} xxl={3} xl={3} lg={4} md={4}>
                  <div className="main">
                    <div className="Step-choose">
                      <h4 className="carddheading">{item.title}</h4>
                      <div className="borderciclee"></div>
                    </div>
                    <div className="circles">
                      <FontAwesomeIcon icon={item.icon} className="icon" />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>

          <div className="Map-Main">
            <iframe
              className="gmap_iframe"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15133.39075304903!2d73.7810866!3d18.5131838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1719574302642!5m2!1sen!2sin"
            ></iframe>
            <div className="content-main">
              <Row className="justify-content-center">
                <Col xxl={6} xl={6} lg={6}>
                  <div className="Contact-Us-Form">
                    <Form>
                      <Row>
                        <Col xxl={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              {...register("name", {
                                required: "Name is required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.name,
                              })}
                              onKeyDown={(event) => {
                                if (!/[A-Z-a-z ]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />{" "}
                            {errors.name && (
                              <div className="invalid-feedback">
                                {errors.name.message}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xxl={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email / Mobile Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="email"
                              {...register("email", {
                                required: "Email / Mobile Number required",
                                // pattern: {
                                //   value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/,
                                //   message: "Email Id Invalid",
                                // },
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.email,
                              })}
                            />{" "}
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email.message}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xxl={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="subject"
                              {...register("subject", {
                                required: "subject is required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.subject,
                              })}
                            />{" "}
                            {errors.subject && (
                              <div className="invalid-feedback">
                                {errors.subject.message}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xxl={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                              style={{ height: "100px" }}
                              {...register("message", {
                                required: "Message required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.message,
                              })}
                            />{" "}
                            {errors.message && (
                              <div className="invalid-feedback">
                                {errors.message.message}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <div className="submit">
                          <Button_Common
                            className="submit"
                            text="Submit"
                            onClick={handleSubmit(onSubmit)}
                            // onClick={() => setModalShow(true)}
                          />
                        </div>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
        <Successfull_Modal
          show={modalShow}
          onHide={handleModalClose}
          message="Submit Successfully!"
        />
      </section>
    </>
  );
};

export default ContactUs;
