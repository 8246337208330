import React from "react";
import CmjBanner from "./CmjBanner/CmjBanner";
import WhyChoose from "./WhyChoose/WhyChoose";
import OurVission from "./OurVission/OurVission";

const CmjIndustry = () => {
  return (
    <>
      <CmjBanner />
      <WhyChoose />
      <OurVission />
    </>
  );
};

export default CmjIndustry;
