import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

// export const getFAQCategory = async () => {
//   try {
//     return await getData(`/without-login/all-faq-categories`);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getFAQs = async () => {
  try {
    return await getData(`/without-login/all-faq`);
  } catch (error) {
    console.log(error);
  }
};
