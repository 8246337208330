import React, { useEffect, useState } from "react";
import "./Successfull_Modal.css";
import { Modal } from "react-bootstrap";
import Successfull_Lottie from "../../../Animation/Successfull_Lottie/Successfull_Lottie";

function Successfull_Modal(props) {
  const { show, onHide, message } = props;

  useEffect(() => {
    let timer;
    if (show) {
      timer = setTimeout(() => {
        onHide();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [show, onHide]);

  return (
    <div className="Successfull_Modal_sec">
      <Modal
        className="Successfull_Modal"
        show={show}
        onHide={onHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="lottie-holder">
            <Successfull_Lottie />
          </div>
          <p className="sub-title">{message}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Successfull_Modal;
