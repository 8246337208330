import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import Form from "react-bootstrap/Form";
import "./TrackOrder.css";
import Common_Button from "../CommonComponent/Button_Common/Button_Common";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router-dom";
import {
  getallOrderOrderedStatus,
  getTrackOrders,
} from "../../utils/apis/home";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { formatDate, ModifyDateFormat } from "../../utils/common";
const TrackOrder = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Track Order", link: "", className: "active" },
  ]);

  const [stepPercentage, setStepPercentage] = useState(0);
  const [showForm, setShowForm] = useState(true);

  // const handleFormSubmit = (e) => {
  //   // Add form submission logic here if needed
  //   console.log(e.target.value);

  //   // Hide the form after 300ms (adjust timing as needed)
  //   setTimeout(() => {
  //     setShowForm(false);
  //   }, 300);

  //   // setTimeout(() => {
  //   //   setShowForm(true);
  //   // }, 4000);
  // };

  //   -------------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [datastatus, setDataStatus] = useState([]);

  const getData = async () => {
    const res = await getallOrderOrderedStatus();
    setDataStatus(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const [result, setResult] = useState({});
  const onSubmit = async (data) => {
    try {
      // console.log(data);

      const res = await getTrackOrders(data.invoice_number);
      // console.log(res);

      if (res.success) {
        setResult(res.data);
        setTimeout(() => {
          setShowForm(false);
        }, 300);
      }
      // getData();
      // reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="TrackOrder">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="track-order">
            <img
              src={process.env.PUBLIC_URL + "/assests/Banner/order.png"}
              className="order"
              alt="Order Banner"
            />
            <div className="content">
              <Row>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={12}
                  className="main-content-holder"
                >
                  <div className="main-content">
                    <h1 className="order-title">
                      Track<br></br> Your Order
                    </h1>
                    <h2 className="sub-title-order sub-title-order mt-md-4 mt-3">
                      Have an order?{" "}
                    </h2>
                    <h2 className="sub-title-order">
                      Want to know where your order is now?
                    </h2>
                  </div>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={12}>
                  {!showForm ? (
                    <div
                      className={`Track-Code  flip-vertical-right ${
                        showForm ? " flip-vertical-right" : ""
                      }`}
                    >
                      <h4 className="code-title">Order Details</h4>
                      <div className="stepper-div">
                        <ProgressBar percent={3}>
                          {datastatus?.map((val, index) => (
                            <Step key={index}>
                              {({ accomplished }) => (
                                <div
                                  className={`indexedStep ${
                                    val.id == result?.order_status_id
                                      ? "accomplished"
                                      : ""
                                  }`}
                                >
                                  <div className="range-text-div">
                                    <h6 className="mini-text">
                                      {val?.name}{" "}
                                      {result?.order_status_histories[index] &&
                                        ModifyDateFormat(
                                          result?.order_status_histories[index]
                                            ?.createdAt
                                        )}
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </Step>
                          ))}
                        </ProgressBar>

                        {/* <ProgressBar percent={3}>
                          {datastatus?.map((val, index) => (
                            <Step key={index}>
                              {({ accomplished = val?.id, index }) => (
                                <div
                                  className={`indexedStep ${
                                    accomplished ? "accomplished" : ""
                                  }`}
                                >
                                  <div className="range-text-div">
                                    <h6 className="mini-text">{val?.name}</h6>
                                  </div>
                                </div>
                              )}
                            </Step>
                          ))}
                        </ProgressBar> */}

                        {/* <Step>
                            {({ accomplished, index }) => (
                              <div
                                className={`indexedStep ${
                                  accomplished ? "accomplished" : ""
                                }`}
                              >
                                <div className="range-text-div">
                                  <h6 className="mini-text">
                                    Order Confirmed on Mon, 23 July
                                  </h6>
                                </div>
                              </div>
                            )}
                          </Step>
                          <Step>
                            {({ accomplished, index }) => (
                              <div
                                className={`indexedStep ${
                                  accomplished ? "accomplished" : ""
                                }`}
                              >
                                <div className="range-text-div">
                                  <h6 className="mini-text">
                                    Order Shipped on Sat, 25 July
                                  </h6>
                                </div>
                              </div>
                            )}
                          </Step>
                          <Step>
                            {({ accomplished, index }) => (
                              <div
                                className={`indexedStep ${
                                  accomplished ? "accomplished" : ""
                                }`}
                              >
                                <div className="range-text-div">
                                  <h6 className="mini-text">
                                    Order Out for Delivery on Tues, 30 July
                                  </h6>
                                </div>
                              </div>
                            )}
                          </Step>
                          <Step>
                            {({ accomplished, index }) => (
                              <div
                                className={`indexedStep ${
                                  accomplished ? "accomplished" : ""
                                }`}
                              >
                                <div className="range-text-div">
                                  <h6 className="mini-text">
                                    Order Delivered On Fri, 3 August
                                  </h6>
                                </div>
                              </div>
                            )}
                          </Step> */}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`Track-Code  ${
                        !showForm ? "flip-vertical-right " : ""
                      }`}
                    >
                      <h4 className="code-title">
                        Enter the track code of your order
                      </h4>
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Order</Form.Label>
                          <Form.Control
                            type="text"
                            name="invoice_number"
                            className={classNames("", {
                              "is-invalid": errors?.invoice_number,
                            })}
                            {...register("invoice_number", {
                              required: "Invoice Number is required",
                            })}
                            placeholder="For eg. Invoice Number INV-1725255014409-5914"
                          />
                        </Form.Group>
                        <h4 className="product">
                          Know the progress of your product delivery.
                        </h4>
                        <div className="submit">
                          <Common_Button
                            text="Submit"
                            className="submit text-p"
                            type="button" // Change type to button
                            onClick={handleSubmit(onSubmit)}
                          />
                        </div>
                      </Form>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="image-overlay"></div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default TrackOrder;
