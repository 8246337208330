import React, { useEffect, useState } from "react";
import "./Register.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import Successfull_Modal from "../../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
import BTC_Register from "./BTC_Register";
import BTB_Register from "./BTB_Register";

function Register(props) {
  const [selectedOption, setSelectedOption] = useState("second");

  // const [modalShow, setModalShow] = useState(false);
  // const navigate = useNavigate();
  // const [passwordVisible, setPasswordVisible] = useState(false);
  // const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  // useEffect(() => {
  //   if (modalShow) {
  //     const timer = setTimeout(() => {
  //       setModalShow(false);
  //       navigate("/login");
  //     }, 3000); // Navigate after 3 seconds
  //     // Clean up timer if component unmounts or modalShow changes
  //     return () => clearTimeout(timer);
  //   }
  // }, [modalShow, navigate]);
  // const togglePasswordVisibility = () => {
  //   setPasswordVisible(!passwordVisible);
  // };
  // const toggleConfirmPasswordVisibility = () => {
  //   setConfirmPasswordVisible(!confirmPasswordVisible);
  // };

  return (
    <>
      <div className="Modal-holder">
        <div className="LogIn">
          <div className="row bg-color me-0 ms-0 justify-content-center LogIn-holder">
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8">
              <div className="row justify-content-center">
                <div className="col-xl-12 col-md-12 col-sm-12">
                  <div className="log_in_form register-form">
                    <div className="login-box-button ">
                      <p>Register</p>
                    </div>
                    <div className="Main-register">
                      <Form className="check-box-holder">
                        {" "}
                        <Form.Check
                          type="radio"
                          name="payment"
                          label="B2B Customer"
                          checked={selectedOption === "second"}
                          onChange={() => setSelectedOption("second")}
                        />
                        <Form.Check
                          type="radio"
                          name="payment"
                          label="B2C Customer"
                          checked={selectedOption === "first"}
                          onChange={() => setSelectedOption("first")}
                        />
                      </Form>
                      <div className="border-regis"></div>

                      {selectedOption === "first" && <BTC_Register />}

                      {selectedOption === "second" && <BTB_Register />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
