import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getSampleRollProducts = async (category_id = "") => {
  try {
    return await getData(`/without-login/sample-roll/products`);
  } catch (error) {
    console.log(error);
  }
};
