import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import "./Help.css";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import { getHelpCategory } from "../../utils/apis/help";
const Help = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Help", link: "/help", className: "active" },
  ]);

  //   -------------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getHelpCategory();
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="Help">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="help-banner">
            <img
              src={process.env.PUBLIC_URL + "/assests/Policybanners/help.png"}
              className="help"
            />
            <div className="content">
              <h1 className="title-main">
                <span className="main-title-border">Get</span>
                HELP
              </h1>
            </div>
            <div className="image-overlay"></div>
          </div>

          <div className="Content-Help">
            <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
              <Row>
                <Col xxl={3} xl={3} lg={4}>
                  <Nav variant="pills" className="flex-column tabs-main">
                    <Nav.Item>
                      {data?.map((item, index) => (
                        <Nav.Link eventKey={index}>
                          <div className="sub-title">{item?.name}</div>
                        </Nav.Link>
                      ))}
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="first">
                        <div className="sub-title">Order-related issues</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <div className="sub-title">Product-related issues</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <div className="sub-title">Payment-related issues</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="four">
                        <div className="sub-title">Account-related issues</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="five">
                        <div className="sub-title">Technical issues</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="six">
                        <div className="sub-title">General inquiries</div>
                      </Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </Col>
                <Col xxl={9} xl={9} lg={8}>
                  <Tab.Content>
                    {data?.map((item, index) => (
                      <Tab.Pane eventKey={index}>
                        <Accordion
                          defaultActiveKey={`${index}0`}
                          className="help-main"
                        >
                          {item?.help?.map((val, index2) => (
                            <Accordion.Item eventKey={`${index}${index2}`}>
                              <Accordion.Header>{val.name}</Accordion.Header>
                              <Accordion.Body>
                                {htmlToReactParser.parse(val?.description) ||
                                  ""}
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}

                          {/* <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              1. Where is my order?
                            </Accordion.Header>
                            <Accordion.Body>
                              You can track your order status by logging into
                              your account or using the tracking number provided
                              in your order confirmation email.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="1" className="mt-4">
                            <Accordion.Header>
                              2. Do we provide installation support to the
                              users?
                            </Accordion.Header>
                            <Accordion.Body>
                              You can track your order status by logging into
                              your account or using the tracking number provided
                              in your order confirmation email.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="2" className="mt-4">
                            <Accordion.Header>
                              3. Do we provide installation support to the
                              users?
                            </Accordion.Header>
                            <Accordion.Body>
                              You can track your order status by logging into
                              your account or using the tracking number provided
                              in your order confirmation email.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="3" className="mt-4">
                            <Accordion.Header>
                              4. Do we provide installation support to the
                              users?
                            </Accordion.Header>
                            <Accordion.Body>
                              You can track your order status by logging into
                              your account or using the tracking number provided
                              in your order confirmation email.
                            </Accordion.Body>
                          </Accordion.Item> */}
                        </Accordion>
                      </Tab.Pane>
                    ))}
                    {/* <Tab.Pane eventKey="first">
                      <Accordion defaultActiveKey="0" className="help-main">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            1. Where is my order?
                          </Accordion.Header>
                          <Accordion.Body>
                            You can track your order status by logging into your
                            account or using the tracking number provided in
                            your order confirmation email.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1" className="mt-4">
                          <Accordion.Header>
                            2. Do we provide installation support to the users?
                          </Accordion.Header>
                          <Accordion.Body>
                            You can track your order status by logging into your
                            account or using the tracking number provided in
                            your order confirmation email.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className="mt-4">
                          <Accordion.Header>
                            3. Do we provide installation support to the users?
                          </Accordion.Header>
                          <Accordion.Body>
                            You can track your order status by logging into your
                            account or using the tracking number provided in
                            your order confirmation email.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="mt-4">
                          <Accordion.Header>
                            4. Do we provide installation support to the users?
                          </Accordion.Header>
                          <Accordion.Body>
                            You can track your order status by logging into your
                            account or using the tracking number provided in
                            your order confirmation email.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Tab.Pane> */}

                    {/* <Tab.Pane eventKey="second">Second tab content</Tab.Pane> */}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Help;
