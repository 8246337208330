import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Country = async () => {
  try {
    return await getData(`/without-login/home/get-country`);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  try {
    return await getData(`/without-login/home/get-state/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/without-login/home/get-city/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Cities = async () => {
  try {
    return await getData(`/without-login/allcity`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (id) => {
  try {
    return await getData(`/without-login/home/get-pincode/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Policy = async (type = "payment_method") => {
  try {
    return await getData(`/without-login/about-us/policy?type=${type}`);
  } catch (error) {
    console.log(error);
  }
};

// ----------------------------------------------------------------

export const getFaqs = async () => {
  try {
    return await getData(`/without-login/all-faq-categories`);
  } catch (error) {
    console.log(error);
  }
};
export const Banners = async () => {
  try {
    return await getData(`/without-login/home/banner`);
  } catch (error) {
    console.log(error);
  }
};

// export const Coupons = async () => {
//   try {
//     return await getData(`/without-login/home/coupons`);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const MaxPrice = async (id) => {
  try {
    return await getData(`/without-login/all-max-price`);
  } catch (error) {
    console.log(error);
  }
};

export const Categories = async (id) => {
  try {
    return await getData(`/without-login/allcategories`);
  } catch (error) {
    console.log(error);
  }
};

export const PopularTags = async (id) => {
  try {
    return await getData(`/without-login/all-popular-tags`);
  } catch (error) {
    console.log(error);
  }
};

export const OrderStatus = async (id) => {
  try {
    return await getData(`/without-login/all-order-ordered-status`);
  } catch (error) {
    console.log(error);
  }
};

export const SocialMedia = async (id) => {
  try {
    return await getData(`/without-login/all-social-media`);
  } catch (error) {
    console.log(error);
  }
};

// /without-login/about-us/return-policy
export const ReturnPolicy = async (id) => {
  try {
    return await getData(`/without-login/about-us/return-policy`);
  } catch (error) {
    console.log(error);
  }
};

// /without-login/about-us/privacy
export const PrivacyPolicy = async (id) => {
  try {
    return await getData(`/without-login/about-us/privacy`);
  } catch (error) {
    console.log(error);
  }
};

// /without-login/about-us/terms
export const TermsConditions = async (id) => {
  try {
    return await getData(`/without-login/about-us/terms`);
  } catch (error) {
    console.log(error);
  }
};

export const CancelOrderReason = async () => {
  try {
    return await getData(`/without-login/all-order-cancel-reason`);
  } catch (error) {
    console.log(error);
  }
};

export const ReturnOrderReason = async () => {
  try {
    return await getData(`/without-login/all-order-return-reason`);
  } catch (error) {
    console.log(error);
  }
};

export const ReturnStatus = async () => {
  try {
    return await getData(`/without-login/all-return-status`);
  } catch (error) {
    console.log(error);
  }
};
