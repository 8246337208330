import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const addCompare = async (d) => {
  try {
    return await postData(`/compare/add`, d);
  } catch (error) {
    console.log(error);
  }
};

export const getCompare = async () => {
  try {
    return await getData(`/compare/show`);
  } catch (error) {
    console.log(error);
  }
};

export const compareContext = async (signin, setComparelist) => {
  if (signin) {
    const res = await getCompare();
    if (res?.success) {
      setComparelist(res?.data);
    }
  } else {
    setComparelist([]);
  }
};
