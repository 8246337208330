import React from "react";
import "./Button_Common.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Submit_Button({
  onClick,
  className,
  margin,
  text,
  border,
  background,
  borderRadius,
  fontSize,
  color,
  fontWeight,
  padding,
  icon,
  showHeart,
  disabled = false,
}) {
  const buttonStyle = {
    background,
    borderRadius,
    border,
    fontSize,
    fontWeight,
    color,
    padding,
    margin,
    className,
  };
  return (
    <>
      <button
        // onClick={onClick}
        disabled={disabled}
        type="submit"
        className={className}
        style={buttonStyle}
      >
        {text}
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className={`heart-icon  ms-1 ${showHeart ? "heart-icon  active" : ""
              }`}
          />
        )}
      </button>
    </>
  );
}

export default Submit_Button;
