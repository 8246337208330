import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Container } from "react-bootstrap";
import { Policy } from "../../utils/apis/master/Master";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router-dom";

function Payment_Method() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Payment Method", link: "" },
    ,
  ]);

  // ----------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await Policy("payment_method");
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="privacy_section">
        <Container>
          <div>
            <Breadcrumbs items={breadcrumbItems} />
          </div>
          <div className="Privacy_main">
            <div className="privacybanner_div ">
              <img
                className="privacybanner"
                src={
                  process.env.PUBLIC_URL + "/assests/Banner/pementbanner.png"
                }
              />
              <div className="banoverlays">
                <p className="bannertitle">Payment Methods</p>
              </div>
            </div>
            <div className="bannertext_main_div">
              {" "}
              <div className="bannertext_div mb-3">
                <p className="text-p mb-0">{data?.short_description}</p>
              </div>
              <div className="bannersubtext_div mb-3">
                {" "}
                {htmlToReactParser.parse(data?.description) || ""}
                <h1 className="bigtextt">Payment Methods</h1>
              </div>
              {/* <div className="bannertext_div mb-3">
                <p className="text-p mb-0">
                  "Our orders are dispatched only after payment is received.
                  Currently, customers can pay via credit and debit cards
                  through Razorpay payment gateways. These payment gateways
                  adhere to the standards set by PCI-DSS as managed by the PCI
                  Security Standards Council, a collaborative effort of brands
                  like Visa, MasterCard, and RupayCards. PCI-DSS requirements
                  ensure the secure handling of credit card information by our
                  store and its service providers."
                </p>
              </div>

              <div className="bannersubtext_div mb-3">
                <p className="privecy_subtitle">
                  What payment methods do you accept?
                </p>
                <p className="privcytext">
                  1. Debit & Credit cards (Visa Cards, Master Cards, Rupay
                  Cards.)
                </p>
                <p className="privcytext">
                  2.UPI/ QR Code (GooglePay, Phonepe, BHIM & More.)
                </p>

                <p className="privcytext">3.Net Banking </p>
                <p className="privcytext">4. Wallet. </p>
                <p className="privcytext">
                  5.Currently, COD (Cash On Delivery) is not available. However,
                  please check our website regularly for updates, as we may
                  introduce COD as a payment option in the future."
                </p>
                <p className="privcytext">
                  6.Or it is possible that COD is available only for some sample
                  products.
                </p>
                <p className="privcytext">
                  {" "}
                  <b>
                    TODAY WE DO NOT WISH TO MAKE ANY FALSE COMMITMENT ON COD
                    OPTION.
                  </b>
                </p>

                <h1 className="bigtextt">Payment Methods</h1>
              </div> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Payment_Method;
