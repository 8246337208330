import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button, Modal } from "react-bootstrap";
import Common_Button from "../../Button_Common/Button_Common";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { useNavigate } from "react-router-dom";
import "./Permision_Delete_Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import { Context } from "../../../../utils/context";
import { updateCartCount } from "../../../../utils/apis/addToCart/addToCart";

function Permision_Delete_Modal(props) {
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (modalSuccessfullShow) {
      timer = setTimeout(() => {
        setSuccessfullModalShow(false);
        navigate("/");
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [modalSuccessfullShow, navigate]);

  //   ----------------------------------------------------------------
  const {
    IMG_URL,
    htmlToReactParser,
    signin,
    setSignin,
    setUsertype,
    usertype,
    Cookies,
    cart,
    setCart,
    wishlist,
  } = useContext(Context);

  const handleLogout = async () => {
    await updateCartCount(false, setCart);
    await setSignin(false);
    await Cookies.remove("cmj_security", { path: "/" });
    await navigate("/");
    props.onHide();
    setSuccessfullModalShow(true);
  };

  return (
    <>
      <Modal
        {...props}
        size="sm"
        className="Modal-holder Permission"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="main-cross">
          <Button onClick={props.onHide}>
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </div>

        <div className="row bg-color me-0 ms-0">
          <div className="col-md-12 p-0">
            <div className="row justify-content-center me-0 ms-0">
              <div className="col-xl-12 col-md-12 col-sm-12 p-0">
                <div className="log_in_form">
                  <div className="heading-holder">
                    <p className="text-center mb-0 sub-title">Do You Want To</p>
                    <p className="bold-text-holder text-center sub-title">
                      <b>Logout from CMJ?</b>
                    </p>
                  </div>

                  <div className="btn-holder d-flex justify-content-between">
                    <Common_Button
                      className="permission-cancle"
                      text="Cancel"
                      onClick={props.onHide}
                    />
                    <Common_Button
                      className="permission-submit"
                      text="Submit"
                      //   onClick={() => {
                      //     props.onHide();
                      //     setSuccessfullModalShow(true);
                      //   }}
                      onClick={handleLogout}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Successfull_Modal
        show={modalSuccessfullShow}
        onHide={() => setSuccessfullModalShow(false)}
        message="Logout Successfully!"
      />
    </>
  );
}

export default Permision_Delete_Modal;
