import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";

import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Warning_Modal from "../Warning_Modal/Warning_Modal";
import "./Heart_Component.css";
import { Context } from "../../../utils/context";
import {
  addWishlist,
  wishlistContext,
} from "../../../utils/apis/Wishlist/Wishlist";

function Heart_Component({ status, product_id }) {
  //   const [isFavorite, setIsFavorite] = useState(false);

  //   const toggleFavorite = async () => {
  //     setIsFavorite(!isFavorite);
  //   };

  //   ------------------------------------------------------------------------------------------------
  const { signin, setWishlist } = useContext(Context);

  const [isFavorite, setIsFavorite] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setIsFavorite(status);
  }, [status]);

  const toggleFavorite = async () => {
    // setIsFavorite(!isFavorite);
    if (signin) {
      const data = {
        product_id,
      };
      await addWishlist(data);
      await wishlistContext(signin, setWishlist);
      await setIsFavorite(!isFavorite);
    } else {
      setModalShow(true);
    }
  };

  //   console.log(product_id);
  return (
    <div className="heart-component">
      <div onClick={toggleFavorite} className="favorite-button">
        <FontAwesomeIcon
          icon={isFavorite ? solidHeart : regularHeart}
          className={isFavorite ? "red-heart" : "black-heart"}
        />
      </div>
      <Warning_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={"Please Login Before Add To Wish List"}
      />
    </div>
  );
}

export default Heart_Component;
