import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./PostSubmission.css";
import Button_Common from "../../Button_Common/Button_Common";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import { useNavigate } from "react-router-dom";
const PostSubmission = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (modalShow) {
      const timer = setTimeout(() => {
        setModalShow(false);
        navigate("/");
      }, 3000);
      //   return () => clearTimeout(timer);
    }
  }, [modalShow, navigate]);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="PostSubmission"
      >
        <Modal.Body>
          <div className="Modal-holder">
            <div className="log_in_form">
              <div className="login-box-button">
                <p>Post-Submission Process</p>
              </div>
            </div>
            <ul className="main-Post">
              <li className="small-text">
                <span className="small-text post">
                  1. Form Submission Confirmation:
                </span>{" "}
                Once you submit the form, you will receive an automated
                confirmation email acknowledging the receipt of your request.
              </li>
              <li className="small-text">
                <span className="small-text post">
                  2. Review by Sales Team:
                </span>{" "}
                Our sales team will review your submitted requirements and
                verify the details provided.
              </li>
              <li className="small-text">
                <span className="small-text post">
                  3. Product & Listing Preparation:
                </span>{" "}
                Our sales team will review your submitted requirements and
                verify the details provided.
              </li>
              <li className="small-text">
                <span className="small-text post">
                  4. Clarification and Communication:
                </span>{" "}
                If any additional information or clarification is needed, our
                sales representative will contact you via the provided contact
                details
              </li>
              <li className="small-text">
                <span className="small-text post">5. Order Confirmation:</span>{" "}
                Upon your approval of the Product, we will confirm your order
                and provide an estimated delivery time.
              </li>
              <li className="small-text">
                <span className="small-text post">6. Payment Processing:</span>{" "}
                You will receive an invoice with payment instructions. Payment
                can be made through your preferred method (Credit Card, Bank
                Transfer, and Debit Card).
              </li>
              <li className="small-text">
                <span className="small-text post">7. Order Processing:</span>{" "}
                Once the payment is confirmed, your order will be processed and
                prepared for shipment
              </li>
              <li className="small-text">
                <span className="small-text post">
                  8. Shipping and Delivery:
                </span>{" "}
                You will receive a shipping notification with tracking details
                once your order has been dispatched
              </li>
              <li className="small-text">
                <span className="small-text post">9. Order Delivered:</span>{" "}
                Your order will be delivered to the provided address within the
                estimated delivery time
              </li>
              <li className="small-text">
                <span className="small-text post">
                  10. Post-Delivery Follow-Up:
                </span>{" "}
                Our customer service team will follow up to ensure that your
                order was delivered satisfactorily and address any additional
                needs or feedback.
              </li>
              <li className="small-text">
                <span className="small-text post">
                  11. Reorder and Support:
                </span>{" "}
                For repeated orders, please refer to your specified reorder
                schedule or contact our support team for assistance.
              </li>
              <p className="small-text post text-center mt-2">
                Thank you for choosing us for your tape requirements. We strive
                to provide you with the best products and services.
              </p>
            </ul>

            <div className="okay mt-2">
              <Button_Common
                onClick={() => {
                  props.Cookies.remove("c_t_c_info_id");
                  setModalShow(true);
                  props.onHide();
                }}
                text="Okay"
                className="orange"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={"Your Tape Requirement Send Successfully!"}
      />
    </>
  );
};

export default PostSubmission;
