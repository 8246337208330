import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const postCustomerTapCI = async (data) => {
  try {
    const res = await postData(
      `/without-login/customize-tap/customer-info`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerTapCI = async (id) => {
  try {
    const res = await getData(
      `/without-login/customize-tap/customer-info/${id}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postCustomerTapPI = async (data) => {
  try {
    const res = await postData(
      `/without-login/customize-tap/product-info`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerTapPI = async (id) => {
  try {
    const res = await getData(
      `/without-login/customize-tap/product-info/${id}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postCustomerTapROD = async (data) => {
  try {
    const res = await postData(`/without-login/customize-tap/r-o-d`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerTapROD = async (id) => {
  try {
    const res = await getData(`/without-login/customize-tap/r-o-d/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
