import toast, { Toaster } from "react-hot-toast";

export const DeletedNotify = (name) => {
  toast.error(`${name} deleted successfully.`, {
    position: "top-right",
    autoClose: 100,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    style: { background: "red", color: "white" },
  });
};

export const SuccessNotify = (name) => {
  toast.success(`${name} successfully.`, {
    position: "top-right",
    autoClose: 100,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    style: { background: "red", color: "white" },
  });
};
