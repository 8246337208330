import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import { useForm } from "react-hook-form";
import {
  getCustomerTapPI,
  postCustomerTapPI,
} from "../../utils/apis/customize_tap";
import classNames from "classnames";
const ProductInformation = ({ handlePrevious, handleNext, Cookies }) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    data.c_t_c_info_id = Cookies.get("c_t_c_info_id");

    const res = await postCustomerTapPI(data);

    if (res?.success) {
      reset(res.data);
      handleNext();
    } else {
      console.error(res);
    }
  };

  const getData = async (id) => {
    const res = await getCustomerTapPI(id);
    reset(res.data);
  };

  useEffect(() => {
    if (Cookies.get("c_t_c_info_id")) {
      getData(Cookies.get("c_t_c_info_id"));
    }
  }, []);
  return (
    <>
      <section className="ProductInformation">
        <div className="Product">
          <p className="sub-title">Product Name*</p>
          <Form className="main-product">
            <div className="radio-btn-input-holder">
              <Row>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="p_name"
                    {...register("p_name", {
                      required: "Product Name is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.p_name,
                    })}
                    value="Transparent Tape"
                    label="Transparent Tape"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="p_name"
                    {...register("p_name", {
                      required: "Product Name is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.p_name,
                    })}
                    value="Brown Tape"
                    label="Brown Tape"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="p_name"
                    {...register("p_name", {
                      required: "Product Name is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.p_name,
                    })}
                    value="Black Tape"
                    label="Black Tape"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="p_name"
                    {...register("p_name", {
                      required: "Product Name is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.p_name,
                    })}
                    value="Green Tape"
                    label="Green Tape"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="Product">
          <p className="sub-title">Size*</p>
          <Form className="main-product">
            <div className="radio-btn-input-holder">
              <Row>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="size"
                    {...register("size", {
                      required: "size is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.size,
                    })}
                    value="1”Inch"
                    label="1”Inch"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="size"
                    {...register("size", {
                      required: "size is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.size,
                    })}
                    value="2”Inch"
                    label="2”Inch"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="size"
                    {...register("size", {
                      required: "size is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.size,
                    })}
                    value="3”Inch"
                    label="3”Inch"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="size"
                    {...register("size", {
                      required: "size is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.size,
                    })}
                    value="4”Inch"
                    label="4”Inch"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="Product">
          <p className="sub-title">Meter per Roll*</p>
          <Form className="main-product">
            <div className="radio-btn-input-holder">
              <Row>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="meter_per_roll"
                    {...register("meter_per_roll", {
                      required: "meter per roll is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.meter_per_roll,
                    })}
                    value="30 Meter"
                    label="30 Meter"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="meter_per_roll"
                    {...register("meter_per_roll", {
                      required: "meter per roll is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.meter_per_roll,
                    })}
                    value="40 Meter"
                    label="40 Meter"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="meter_per_roll"
                    {...register("meter_per_roll", {
                      required: "meter per roll is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.meter_per_roll,
                    })}
                    value="50 Meter"
                    label="50 Meter"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="meter_per_roll"
                    {...register("meter_per_roll", {
                      required: "meter per roll is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.meter_per_roll,
                    })}
                    value="65 Meter"
                    label="65 Meter"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="meter_per_roll"
                    {...register("meter_per_roll", {
                      required: "meter per roll is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.meter_per_roll,
                    })}
                    value="100 Meter"
                    label="100 Meter"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="Product">
          <p className="sub-title">Box Quantity*</p>
          <Form className="main-product">
            <div className="radio-btn-input-holder">
              <Row>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="box_quantity"
                    {...register("box_quantity", {
                      required: "box quantity is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.box_quantity,
                    })}
                    value="5 Boxes"
                    label="5 Boxes"
                  />
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="box_quantity"
                    {...register("box_quantity", {
                      required: "box quantity is required",
                    })}
                    className={classNames("sub-title", {
                      "is-invalid": errors?.box_quantity,
                    })}
                    value="10 Boxes"
                    label="10 Boxes"
                  />
                </Col>
                {/* <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                  <Form.Check
                    type="radio"
                    name="name"
                    label="Above"
                    className="sub-title"
                  />
                </Col> */}
              </Row>
            </div>
          </Form>
        </div>
        <div className="apply_now_btn">
          <Button_Common
            onClick={handlePrevious}
            className="back-btn me-3 small-text back"
            text="BACK"
          >
            Back
          </Button_Common>

          <Button_Common
            type="button"
            // onClick={handleNext}
            onClick={handleSubmit(onSubmit)}
            className="tabs-btn small-text orange"
            text="SAVE & NEXT"
          >
            Next
          </Button_Common>
        </div>
      </section>
    </>
  );
};

export default ProductInformation;
