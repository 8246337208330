import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

function Warning_Modal(props) {
  const { show, onHide, message } = props;

  useEffect(() => {
    let timer;
    if (show) {
      timer = setTimeout(() => {
        onHide();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [show, onHide]);

  return (
    <div className="Successfull_Modal_sec">
      <Modal
        className="Successfull_Modal"
        show={show}
        onHide={onHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="close">
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="lottie-holder">{/* <Successfull_Lottie /> */}</div>
          <p>{message}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Warning_Modal;
