import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getBulkOrderProductsCategory = async (category_id = "") => {
  try {
    return await getData(
      `/without-login/bulk-order/products?category_id=${category_id}`
    );
  } catch (error) {
    console.log(error);
  }
};
