import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Button_Commom from "../CommonComponent/Button_Common/Button_Common";
import "./Profile.css";
import Successfull_Modal from "../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
import { GetProfile, ProfileEdit } from "../../utils/apis/login/Login";
import { useForm } from "react-hook-form";
import { RegxExpression } from "../../utils/common";
import classNames from "classnames";
const Profile = () => {
  //   //   const [isEditing, setIsEditing] = useState(false);

  //   const handleEditClick = () => {
  //     // setIsEditing(true);
  //   };

  //   const handleSaveClick = () => {
  //     // Save functionality here
  //     // setIsEditing(false);
  //   };
  const [modalShow, setModalShow] = useState(false);

  //   ------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------
  const [editMode, setEditMode] = useState(false);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = () => {
    // Save functionality here
    // setIsEditing(false);
  };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data);
    const res = await ProfileEdit(data);

    if (res?.success) {
      // console.log(res);
      clearErrors();
      setEditMode(false);
      setModalShow(true);
    } else {
      if (res?.message?.contact_no) {
        setError("contact_no", {
          message: res?.message?.contact_no,
        });
      }

      if (res?.message?.email) {
        setError("email", {
          message: res?.message?.email,
        });
      }
    }
  };

  const getData = async () => {
    const res = await GetProfile();
    reset(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="Profile">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={8} xl={8} lg={8} md={8}>
              <div className="Profile-Mail">
                <div className="icon">
                  {editMode ? <h5>Edit Profile</h5> : <h5> Profile</h5>}
                  {!editMode && (
                    <FontAwesomeIcon
                      onClick={handleEditClick}
                      icon={faPenToSquare}
                    />
                  )}
                </div>
                <div className="Profile-Form">
                  <Form>
                    <Row className="Details">
                      <Col xxl={6} xl={6} lg={6} md={6}>
                        {" "}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="sub-title">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!editMode}
                            placeholder="First Name"
                            {...register("first_name", {
                              required: "First name required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.first_name,
                              "is-valid": getValues("first_name"),
                            })}
                            onKeyDown={(event) => {
                              if (!RegxExpression.name.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6}>
                        {" "}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="sub-title">
                            Last Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!editMode}
                            placeholder="Last Name"
                            {...register("last_name", {
                              required: "Last name required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.last_name,
                              "is-valid": getValues("last_name"),
                            })}
                            onKeyDown={(event) => {
                              if (!RegxExpression.name.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="Details">
                      <Col xxl={6} xl={6} lg={6} md={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="sub-title">Email</Form.Label>
                          <Form.Control
                            type="email"
                            disabled={!editMode}
                            placeholder="Email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: RegxExpression.email,
                                message: "Invalid email address",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.email,
                              "is-valid": getValues("email"),
                            })}
                          />{" "}
                          {errors?.email && (
                            <sup className="text-danger">
                              {errors?.email?.message}
                            </sup>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6}>
                        <Form.Group
                          className="no-arrows-filed mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="sub-title">
                            Mobile Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!editMode}
                            className={classNames("", {
                              "is-invalid": errors?.contact_no,
                              "is-valid": getValues("contact_no"),
                            })}
                            name="contact_no"
                            placeholder="Enter Mobile Number"
                            {...register("contact_no", {
                              required: "Field is required",
                              minLength: {
                                value: 10,
                                message:
                                  "Number should be at least 10 characters",
                              },
                            })}
                            onKeyDown={(event) => {
                              if (!RegxExpression.phone.test(event.key)) {
                                if (event.key !== "Backspace") {
                                  event.preventDefault();
                                }
                              }
                            }}
                            maxLength={10}
                          />{" "}
                          {errors?.contact_no && (
                            <sup className="text-danger">
                              {errors?.contact_no?.message}
                            </sup>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Form className="Details">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="sub-title">Password</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder={editMode ? "" : "Pratiksha@123"}
                        className="small-text"
                        readOnly={!editMode}
                      />
                    </Form.Group>
                  </Form> */}
                  </Form>
                </div>
              </div>
              {editMode && (
                <div
                  className="Save"
                  // onClick={() => setModalShow(true)}
                >
                  <Button_Commom
                    text="Update"
                    className="save orange small-text"
                    // onClick={handleSaveClick}
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <Successfull_Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          message="Profile Updated Successfully!"
        />
      </section>
    </>
  );
};

export default Profile;
