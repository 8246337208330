import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./PdfMain.css";
import Button_Common from "../../Button_Common/Button_Common";
import { Context } from "../../../../utils/context";
import axios from "axios";
import { getDownloadDataPDF } from "../../../../utils/api";
const PdfMain = (props) => {
  const { IMG_URL } = useContext(Context);
  const downloadFile = async (id, filename) => {
    await getDownloadDataPDF(`/order/orders/download/${id}`, filename);
  };
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Main-Pdf"
      >
        <Modal.Body>
          {/* <img
            src={process.env.PUBLIC_URL + "/assests/Checkout/Image.png"}
            className="pdf"
          /> */}
          <object data={IMG_URL + props?.show?.invoice} />
        </Modal.Body>
        <Modal.Footer>
          <Button_Common
            text="Cancel"
            className="cancel small-text"
            onClick={props.onHide}
          />
          <Button_Common
            // download={IMG_URL + props?.show}
            onClick={() =>
              downloadFile(props?.show?.id, props?.show?.invoice_number)
            }
            text="Download"
            className="orange small-text"
          />
          {/* <Button >Close</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PdfMain;
