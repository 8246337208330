import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductCardSale from "../CommonComponent/ProductCardSale/ProductCardSale";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import ProductCard from "../CommonComponent/ProductCard/ProductCard";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { product_coupon, UserType } from "../../utils/common";
function Wishlist() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Account", link: "" },
    { text: "wishlist", link: "" },
    ,
  ]);

  const product = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
  ];

  // ------------------------------------------------------------------------------------------------

  const { wishlist, IMG_URL, usertype } = useContext(Context);

  return (
    <>
      <section className="comparelist_section">
        <Container>
          <div className="comparelist_main">
            <div>
              <Breadcrumbs items={breadcrumbItems} />
            </div>
            <div>
              <Row>
                {wishlist?.map((item, index) => (
                  <Col
                    xxl={3}
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    className="mb-3"
                    key={index}
                  >
                    <div>
                      <ProductCard
                        // id={item.id}
                        // image={item.image}
                        // title={item.name}
                        // discount_percentage={item.discount_percentage}
                        // short_description={item?.short_description}
                        // price={
                        //   usertype == UserType.B2BCustomer
                        //     ? item?.btb_price
                        //     : item?.btc_price
                        // }
                        // mrp={item?.mrp}

                        id={item.id}
                        image={item.image}
                        title={item.name}
                        average_rating={item.average_rating}
                        discount_percentage={item.discount_percentage}
                        short_description={item?.short_description}
                        price={
                          usertype == UserType.B2BCustomer
                            ? item?.btb_price
                            : item?.btc_price
                        }
                        mrp={item?.mrp}
                        save={
                          product_coupon.Percentage ===
                          item?.product_cuppon?.coupon_type
                            ? item?.product_cuppon?.discount + " %"
                            : "₹ " + item?.product_cuppon?.total_amount
                        }
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

            <div>
              <Link to="/">
                <p className="text-p text-decoration-underline text-center mt-3">
                  Continue Shopping
                </p>
              </Link>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Wishlist;
