import React, { useState, useEffect, useRef } from "react";
import { Form, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OTP_Modal.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  ForgetPasswordOtpVerify,
  verifyEmailOTP,
} from "../../../utils/apis/otp/otp";
import Common_Button from "../../CommonComponent/Button_Common/Button_Common";
function OTP_Modal() {
  const navigate = useNavigate();
  //   const [otp, setOtp] = useState(Array(4).fill(""));
  //   const inputRefs = useRef([]);

  const handleClose = () => {
    navigate("/login");
  };

  //   const handleInput = (event, index) => {
  //     const { value } = event.target;
  //     if (/^\d*$/.test(value)) {
  //       const newOtp = [...otp];
  //       newOtp[index] = value;
  //       setOtp(newOtp);
  //       if (value !== "" && index < otp.length - 1) {
  //         inputRefs.current[index + 1].focus();
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     if (otp.every((digit) => digit !== "")) {
  //       navigate("/set-new-password");
  //     }
  //   }, [otp, navigate]);

  //   ----------------------------------------------------------------

  const { email_or_contact } = useParams();
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      otp: new Array(4).fill(""),
    },
  });
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      setValue(`otp.${index}`, value);
      if (getValues("otp").length == 4) {
        clearErrors();
      }
      if (value !== "" && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !getValues(`otp.${index}`) && index > 0) {
      setValue(`otp.${index - 1}`, "");
      inputRefs.current[index - 1].focus();
    } else if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    const otp = data.otp.join("");
    // console.log("OTP Length:", otp.length);

    if (otp.length === 4) {
      const data = {
        email: email_or_contact,
        contact_no: email_or_contact,
        otp: otp,
      };

      // console.log("OTP Submitted:", data);

      const res = await ForgetPasswordOtpVerify(data);
      // console.log(res);
      if (res.success) {
        reset();
        navigate(`/set-new-password/${email_or_contact}`);
      } else {
        setError("otp", { message: "Invalid OTP" });
      }
    } else {
      setError("otp", { message: "Enter Valid OTP" });
    }
    // const res = await Register(data);
    // if (res?.success) {
    //   reset();
    //   clearErrors();
    //   props.onHide();
    // } else {
    //   if (res?.message?.both) {
    //     setError("email", { message: res?.message?.both });
    //     setError("contact_no", { message: "" });
    //   }
    //   if (res?.message?.email) {
    //     setError("email", { message: res?.message?.email });
    //   }
    //   if (res?.message?.contact_no) {
    //     setError("contact_no", { message: res?.message?.contact_no });
    //   }
    // }
  };

  return (
    <div className="Otp-main">
      <div className="LogIn">
        <Container>
          <div className="row justify-content-center me-0 ms-0">
            <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-7 ">
              <div className="otp-main-start">
                <div className="log_in_form">
                  <div className="heading-holder sub-title">
                    OTP has been Sent to the Email / Mobile Number
                  </div>

                  <Form>
                    <div className="otp-holder">
                      {/* {[...Array(4)].map((_, index) => (
                        <div className="otp-text-holder" key={index}>
                          <Form.Group
                            className="mb-3"
                            controlId={`otp-${index}`}
                          >
                            <Form.Control
                              type="text"
                              maxLength={1}
                              value={otp[index]}
                              onChange={(e) => handleInput(e, index)}
                              ref={(el) => (inputRefs.current[index] = el)}
                            />
                          </Form.Group>
                        </div>
                      ))} */}
                      {[...Array(4)].map((_, index) => (
                        <div className="otp-text-holder" key={index}>
                          <Form.Group
                            className="mb-3"
                            controlId={`otp-${index}`}
                          >
                            <Controller
                              name={`otp.${index}`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Form.Control
                                  type="text"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleChange(e.target.value, index);
                                  }}
                                  onKeyDown={(e) => handleKeyDown(e, index)}
                                  ref={(el) => (inputRefs.current[index] = el)}
                                  maxLength="1"
                                />
                              )}
                            />
                          </Form.Group>
                        </div>
                      ))}
                    </div>
                    <div className="btn-holder d-flex justify-content-center">
                      {errors?.otp && (
                        <sup className="text-danger">
                          {errors?.otp?.message}
                        </sup>
                      )}
                    </div>

                    <div className="btn-holder d-flex justify-content-center">
                      <Link to="/">
                        <Common_Button
                          className="button button--webcoderskull button--border-thick button--text-medium cancle-btn sub-title"
                          text="Cancel"
                        />
                      </Link>

                      <Common_Button
                        className="button button--webcoderskull button--border-thick button--text-medium login-btn sub-title submit orange"
                        text="Submit"
                        onClick={handleSubmit(onSubmit)}
                      />
                    </div>
                  </Form>

                  <div className="otp-close">
                    <div className="otp" onClick={handleClose}>
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default OTP_Modal;
