import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import Accordion from "react-bootstrap/Accordion";
import "./FAQ.css";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import { getFAQs } from "../../utils/apis/faqs";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Frequently asked questions", link: "", className: "active" },
  ]);

  //   -------------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getFAQs();
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="FAQ">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="faq-banner">
            <img
              src={process.env.PUBLIC_URL + "/assests/Policybanners/faq.png"}
              className="faq"
            />
            <div className="content">
              <h1 className="title-main">
                Frequently asked <br></br>
                <span className="main-title-border">questions</span>
              </h1>
            </div>
            <div className="image-overlay"></div>
          </div>

          {/* contant faq */}
          <Accordion defaultActiveKey="0" className="faq-main">
            {data?.map((item, index) => (
              <Accordion.Item eventKey={index} className="mt-4">
                <Accordion.Header>{item?.name}</Accordion.Header>
                <Accordion.Body>
                  {htmlToReactParser?.parse(item.description) || ""}
                </Accordion.Body>
              </Accordion.Item>
            ))}

            {/* <Accordion.Item eventKey="0">
              <Accordion.Header>
                Do we provide installation support to the users?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
                rhoncus ut, imperdiet a, venenas
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="mt-4">
              <Accordion.Header>
                Do we provide installation support to the users?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
                rhoncus ut, imperdiet a, venenas
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="mt-4">
              <Accordion.Header>
                Do we provide installation support to the users?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
                rhoncus ut, imperdiet a, venenas
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="mt-4">
              <Accordion.Header>
                Do we provide installation support to the users?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
                rhoncus ut, imperdiet a, venenas
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </Container>
      </section>
    </>
  );
};

export default FAQ;
