import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import {
  getCustomerTapCI,
  postCustomerTapCI,
  postCustomerTapPI,
} from "../../utils/apis/customize_tap";
const CustomerInformation = ({ handleNext, Cookies }) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    // console.log("data", data);
    const res = await postCustomerTapCI(data);
    // console.log(res);
    if (res?.success) {
      await Cookies.set("c_t_c_info_id", res?.data?.id);
      reset(res.data);
      handleNext();
    } else {
      console.error(res);
    }
  };

  const getData = async (id) => {
    const res = await getCustomerTapCI(id);
    reset(res.data);
  };

  useEffect(() => {
    if (Cookies.get("c_t_c_info_id")) {
      getData(Cookies.get("c_t_c_info_id"));
    }
  }, []);

  return (
    <>
      <section className="Customer-Information">
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="sub-title">Customer Full Name*</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              {...register("full_name", {
                required: "Full Name is required",
              })}
              className={classNames("", {
                "is-invalid": errors?.full_name,
              })}
              onKeyDown={(event) => {
                if (!/[A-Z-a-z ]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />{" "}
            {errors.full_name && (
              <div className="invalid-feedback">{errors.full_name.message}</div>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="sub-title">Address*</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Address..."
              style={{ height: "100px" }}
              {...register("address", {
                required: "Address required",
              })}
              className={classNames("", {
                "is-invalid": errors?.address,
              })}
            />{" "}
            {errors.address && (
              <div className="invalid-feedback">{errors.address.message}</div>
            )}
          </Form.Group>

          <Row>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="sub-title">Phone Number*</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_no"
                  placeholder="Contact No."
                  {...register("contact_no", {
                    required: "Contact no is required",
                    validate: {
                      isTenDigits: (value) =>
                        (value && value.length === 10) ||
                        "Contact number must be 10 digits",
                      isNumeric: (value) =>
                        /^\d+$/.test(value) || "Contact number must be numeric",
                    },
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.contact_no,
                    "is-valid":
                      !errors?.contact_no &&
                      getValues("contact_no")?.length === 10,
                  })}
                  onKeyDown={(event) => {
                    const { key } = event;
                    if (
                      !/[0-9]/.test(key) &&
                      key !== "Backspace" &&
                      key !== "Tab"
                    ) {
                      event.preventDefault();
                    }
                    if (
                      event.target.value?.length >= 10 &&
                      key !== "Backspace" &&
                      key !== "Tab"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
                {errors.contact_no && (
                  <span className="text-danger">
                    {errors.contact_no.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="sub-title">Email Address*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="email"
                  className={classNames("", {
                    "is-invalid": errors?.email,
                  })}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address",
                    },
                    validate: (value) => {
                      // Extract the domain and TLD
                      const domainPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/;
                      const match = value.match(domainPattern);
                      if (match) {
                        const domainParts = match[1].split(".");
                        const tld = match[2];

                        // Ensure the domain and TLD are not the same
                        if (domainParts[domainParts.length - 1] === tld) {
                          return "Domain and top-level domain must be different";
                        }
                      }
                      return true;
                    },
                  })}
                />

                {errors.email && (
                  <span className="text-danger">{errors.email.message}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="Save-Btn">
            <Button_Common
              type="button"
              onClick={handleSubmit(onSubmit)}
              //   onClick={handleNext}
              className="tabs-btn small-text orange"
              text="Save & Next"
            >
              Next
            </Button_Common>
          </div>
        </Form>
      </section>
    </>
  );
};

export default CustomerInformation;
