import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";

import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Warning_Modal from "../Warning_Modal/Warning_Modal";
import { Context } from "../../../utils/context";

import {
  addCompare,
  compareContext,
} from "../../../utils/apis/Compare/Compare";
import { useNavigate } from "react-router-dom";

function Compare_Component({ status, product_id }) {
  //   const [isFavorite, setIsFavorite] = useState(false);

  //   const toggleFavorite = async () => {
  //     setIsFavorite(!isFavorite);
  //   };

  //   ------------------------------------------------------------------------------------------------
  const { signin, setComparelist } = useContext(Context);

  const navigate = useNavigate();

  const [isFavorite, setIsFavorite] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setIsFavorite(status);
  }, [status]);

  const toggleFavorite = async () => {
    setIsFavorite(!isFavorite);
    if (signin) {
      const data = {
        product_id,
      };
      await addCompare(data);
      await compareContext(signin, setComparelist);
      await setIsFavorite(!isFavorite);
      navigate("/comparelist");
    } else {
      setModalShow(true);
    }
  };

  //   console.log(product_id);
  return (
    <>
      {/* <div onClick={toggleFavorite} className="favorite-button"></div> */}

      {/* <Link to={"/comparelist"}> */}
      <div onClick={toggleFavorite} className="compare mt-1">
        <img
          src={process.env.PUBLIC_URL + "/assests/Products/compress.png"}
          className="tape"
        />
      </div>
      {/* </Link> */}
      <Warning_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={"Please Login Before Add To Compare List"}
      />
    </>
  );
}

export default Compare_Component;
