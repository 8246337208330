import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import { Policy } from "../../utils/apis/master/Master";
function Security_Trust() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Security Trust", link: "" },
    ,
  ]);

  // ----------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await Policy("security_trust");
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="privacy_section">
        <Container>
          <div>
            <Breadcrumbs items={breadcrumbItems} />
          </div>
          <div className="Privacy_main">
            <div className="privacybanner_div ">
              <img
                className="privacybanner"
                src={
                  process.env.PUBLIC_URL + "/assests/Banner/securitybanner.png"
                }
              />
              <div className="banoverlays">
                <p className="bannertitle">Security Trust</p>
              </div>
            </div>
            <div className="bannertext_main_div">
              <div className="bannertext_div mb-3">
                <p className="text-p mb-0">{data?.short_description}</p>
              </div>

              <div className="bannersubtext_div mb-3">
                {" "}
                {htmlToReactParser.parse(data?.description) || ""}
                <h1 className="bigtextt">Security And trust</h1>
              </div>
              {/* <div className="bannertext_div mb-3">
                <p className="text-p mb-0">
                  At CMJ Industries, we take the security and privacy of our
                  customers very seriously. Our website is protected by an SSL
                  certificate, ensuring that all data transmitted between your
                  browser and our server is encrypted and secure. We have also
                  implemented various other security measures to prevent
                  unauthorized access, data breaches, and other security
                  threats.
                </p>
              </div>

              <div className="bannersubtext_div mb-3">
                <p className="privecy_subtitle">Trustworthy Practices</p>
                <p className="privcytext ps-0">
                  We are committed to building trust with our customers and
                  maintaining a secure online environment. To this end, we
                </p>
                <p className="privcytext">
                  - Use industry-standard encryption to protect sensitive
                  information{" "}
                </p>
                <p className="privcytext">
                  - Implement robust security protocols to prevent unauthorized
                  access{" "}
                </p>
                <p className="privcytext">
                  - Regularly update and patch our systems to prevent
                  vulnerabilities{" "}
                </p>
                <p className="privcytext">
                  - Use secure payment gateways to process transactions{" "}
                </p>
                <p className="privcytext">
                  - Comply with relevant data protection regulations and laws
                </p>

                <h1 className="bigtextt">Security And trust</h1>
              </div> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Security_Trust;
