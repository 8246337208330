import React, { useState, useEffect } from "react";
import CountUp from "react-countup";

const CoupponCountdownTimer = ({ endDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate + "T23:59:59") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  return (
    <>
      <div className="countdown">
        <div className="main-time me-2">
          <span id="hours" className="small-text">
            <CountUp start={timeLeft.days} end={timeLeft.days} duration={1} />
          </span>
          <div className="label small-text">d:</div>
        </div>
        <div className="main-time me-2">
          <span id="hours" className="small-text">
            <CountUp start={timeLeft.hours} end={timeLeft.hours} duration={1} />
          </span>
          <div className="label small-text">h:</div>
        </div>
        <div className="main-time me-2">
          <span id="minutes" className="small-text">
            <CountUp
              start={timeLeft.minutes}
              end={timeLeft.minutes}
              duration={1}
            />
          </span>
          <div className="label small-text">m:</div>
        </div>
        <div className="main-time">
          <span id="seconds" className="small-text">
            <CountUp
              start={timeLeft.seconds}
              end={timeLeft.seconds}
              duration={1}
            />
          </span>
          <div className="label small-text">s</div>
        </div>
      </div>

      {/* <div className="countdown">
        <div className="main-time me-2">
          <span id="hours" className="small-text">
            {time.hours}
          </span>
          <div className="label small-text">h:</div>
        </div>
        <div className="main-time me-2">
          <span id="minutes" className="small-text">
            {time.minutes}
          </span>
          <div className="label small-text">m:</div>
        </div>
        <div className="main-time">
          <span id="seconds" className="small-text">
            {time.seconds}
          </span>
          <div className="label small-text">s</div>
        </div>
      </div> */}
    </>
  );
};

export default CoupponCountdownTimer;
