import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../../components/CommonComponent/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNoteSticky,
  faTruckFast,
  faBox,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import "./Order.css";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import { Rating } from "react-simple-star-rating";
import PdfMain from "../CommonComponent/Common_Modal/PdfMain/PdfMain";
import SelectOption from "../CommonComponent/Common_Modal/SelectOption/SelectOption";
import { Link } from "react-router-dom";

const Order = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "My account", link: "" },
    { text: "Order", link: "", className: "active" },
  ]);

  const [currentStep, setCurrentStep] = useState(1); // Only the first step is completed
  const [modalShow, setModalShow] = useState(false);
  const [modalCancleShow, setModalCancleShow] = useState(false);
  return (
    <>
      <section className="Order">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="Main-Order-Card">
            <Row>
              {/* <Col xxl={3} xl={3} lg={3} md={12}>
                                <div className="main-back">
                                    <div className="image-main">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Products/Tape2.png"}
                                            className="tape"
                                        />
                                    </div>
                                </div>
                            </Col> */}
              <Col xxl={12} xl={12} lg={12} md={12}>
                <div className="Content">
                  <div className="order-content">
                    <div className="Content-main">
                      <h4 className="commmontext">Brown Tape</h4>
                      <p className="small-text">
                        2" 40 Meter, 38 Micron, Per Box 72 Nos
                      </p>
                      <p className="small-text">Order Number: 0005-12122023</p>
                      <p className="small-text">Payment: Online</p>
                      <div className="price-main">
                        <p className="commmontext price me-4">Total: ₹3000</p>
                        <p className="main-price">
                          <span className="rate-price">₹ 1,234.00 </span>
                          <br />
                          (Inclusive of all taxes)
                        </p>
                      </div>
                      <p className="small-text mt-2">
                        Total Additional Charges: ₹ 50
                      </p>
                    </div>
                    <div className="view">
                      <Link to={"/b2bbulkorder"}>
                        <p className="small-text">View Product</p>
                      </Link>
                    </div>
                  </div>

                  <div className="steps ">
                    {[
                      {
                        step: 1,
                        name: "Order Placed",
                        icon: faNoteSticky,
                        date: "9 Jun, 2024, 11:45 am",
                      },
                      {
                        step: 2,
                        name: "Processing",
                        icon: faBox,
                        date: "11 Jun, 2024",
                      },
                      {
                        step: 3,
                        name: "Shipping",
                        icon: faTruckFast,
                        date: "10 Jun, 2024",
                      },
                      {
                        step: 4,
                        name: "Delivery On",
                        icon: faBox,
                        date: "11 Jun, 2024",
                      },
                    ].map(({ step, name, icon, date }) => (
                      <React.Fragment key={step}>
                        <div
                          className={`step step${step} ${
                            currentStep >= step ? "completed " : "incompleted"
                          }`}
                        >
                          <div className="step-name">
                            <div
                              className={`circle me-3 ${
                                currentStep >= step ? "circle-fill" : ""
                              }`}
                            >
                              <FontAwesomeIcon icon={icon} />
                            </div>
                            <p className="small-text">{name}</p>
                          </div>
                          <p className="sub">{date}</p>
                        </div>
                        {step < 4 && (
                          <div
                            className={`border-card ${
                              currentStep >= step
                                ? "border-card-before-after"
                                : "border-card-before-after-incomplete"
                            }`}
                          ></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="Btn-main">
                    <Link to={"/return-order"}>
                      <Button_Common
                        text="Return Order"
                        className="back return1 small-text"
                      />
                    </Link>
                    <Link to={"/cancel-order"}>
                      <Button_Common
                        text="Cancel  Order"
                        className="orange cancel small-text"
                      />
                    </Link>
                    <Button_Common
                      onClick={() => setModalShow(true)}
                      text="Download Invoice"
                      icon={faDownload}
                      className=" return small-text"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row>
                            <Col xxl={3} xl={3} lg={3} md={12} className="main">
                              
                            </Col>
                            <Col xxl={9} xl={9} lg={9} md={12}>
                                <div className="Content">
                                    <div className="order-content">
                                        <div className="Content-main">
                                            <h4 className="commmontext">Brown Tape</h4>
                                            <p className="small-text">2" 40 Meter, 38 Micron, Per Box 72 Nos</p>
                                            <p className="small-text">Order Number: 0005-12122023</p>
                                            <p className="small-text">Payment: Online</p>
                                            <div className="price-main">
                                                <p className="commmontext price me-4">Total: ₹3000</p>
                                                <p className="main-price">
                                                    ₹ 1,234.00 <br />
                                                    (Inclusive of all taxes)
                                                </p>
                                            </div>
                                            <p className="small-text mt-2">Total Additional Charges: ₹ 50</p>
                                        </div>
                                        <div className="view">
                                            <p className="small-text">View Product</p>
                                        </div>
                                    </div>

                                    <div className="steps mt-2">
                                        <div className={`step step1 ${currentStep >= 1 ? "completed" : ""}`}>
                                            <div className="step-name">
                                                <div className={`circle me-3 ${currentStep >= 1 ? "yellow" : ""}`}>
                                                    <FontAwesomeIcon icon={faNoteSticky} className="" />
                                                </div>
                                                <p className="small-text">Order Placed</p>
                                            </div>
                                            <p className="sub">9 Jun, 2024, 11:45 am</p>
                                        </div>
                                        <div className="border-card"></div>
                                        <div className={`step step2 ${currentStep >= 2 ? "completed" : ""}`}>
                                            <div className="step-name">
                                                <div className={`circle me-3 ${currentStep >= 2 ? "yellow" : ""}`}>
                                                    <FontAwesomeIcon icon={faTruckFast} />
                                                </div>
                                                <p className="small-text">Shipping</p>
                                            </div>
                                            <p className="sub">10 Jun, 2024</p>
                                        </div>
                                        <div className="border-card"></div>
                                        <div className={`step step3 ${currentStep >= 3 ? "completed" : ""}`}>
                                            <div className="step-name">
                                                <div className={`circle me-3 ${currentStep >= 3 ? "yellow" : ""}`}>
                                                    <FontAwesomeIcon icon={faBox} />
                                                </div>
                                                <p className="small-text">Delivery On</p>
                                            </div>
                                            <p className="sub">11 Jun, 2024</p>
                                        </div>
                                    </div>
                                    <div className="Btn-main">
                                        <Button_Common text="Return Order" className="back return1 small-text" />
                                        <Button_Common text="Cancel Order" className="orange cancel small-text" />
                                        <Button_Common
                                            onClick={() => setModalShow(true)}
                                            text="Download Invoice"
                                            icon={faDownload}
                                            className=" return small-text"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
          </div>
          <div className="card-product">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={12}>
                <div className="main-back">
                  <div className="image-main">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assests/Products/Tape2.png"
                      }
                      className="tape"
                    />
                  </div>
                </div>
              </Col>
              <Col xxl={9} xl={9} lg={9} md={12}>
                <Row>
                  <Col xxl={9} xl={8} lg={8} md={8}>
                    <h4 className="commmontext">Brown Tape</h4>
                    <p className="sub">2" 40 Meter,38 Micron, Per Box 72 Nos</p>
                    <p className="sub">Order Number: 0005-12122023</p>
                    <p className="sub">Payment : Online</p>
                    <div className="price-main">
                      <p className="commmontext price me-4">Total: ₹3000</p>
                      <p className="main-price">
                        <span className="rate-price">₹ 1,234.00 </span>
                        <br />
                        (Inclusive of all taxes)
                      </p>
                    </div>
                    <p className="sub">Total Additional Charges : ₹ 50</p>
                  </Col>
                  <Col xxl={3} xl={4} lg={4} md={4}>
                    <div className="view">
                      <Link to={"/b2bbulkorder"}>
                        <p className="small-text">View Product</p>
                      </Link>
                    </div>
                    <div className="download">
                      <Button_Common
                        onClick={() => setModalShow(true)}
                        text="Download Invoice"
                        icon={faDownload}
                        className=" return small-text"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="Delivered mt-1">
            <p className="sub-title  d">Delivered by July 2024</p>
            <div className="rate">
              <p className="sub-title r mb-0 mt-1">Rate Us:</p>
              <Rating />
            </div>
          </div>
        </Container>
      </section>
      <PdfMain show={modalShow} onHide={() => setModalShow(false)} />
      {/* <SelectOption
        show={modalCancleShow}
        onHide={() => setModalCancleShow(false)}
      /> */}
    </>
  );
};

export default Order;
