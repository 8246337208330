import React, { useContext, useEffect, useState } from "react";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import Breadcrumbs from "../../components/CommonComponent/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNoteSticky,
  faTruckFast,
  faBox,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./ReturnOrder.css";
import SelectOption from "../CommonComponent/Common_Modal/SelectOption/SelectOption";
import { Link, useNavigate, useParams } from "react-router-dom";
import Successfull_Modal from "../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
import { Context } from "../../utils/context";
import {
  GetOrderDetails,
  ReturnOrder,
} from "../../utils/apis/myAccount/myAccount";
import { Order_Status, Order_Status_icon } from "../../utils/common";
import { OrderStatus } from "../../utils/apis/master/Master";
import SelectOptionReturn from "../CommonComponent/Common_Modal/SelectOptionReturn/SelectOptionReturn";
import { useForm } from "react-hook-form";
const ReturnOrderComponent = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "My account", link: "" },
    { text: "Order", link: "/your-orders" },
    { text: "Return Order", link: "", className: "active" },
  ]);
  const [modalSuccessShow, setModalSuccessShow] = React.useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Only the first step is completed
  const [modalShow, setModalShow] = useState(false);

  // ----------------------------------------------------------------

  const { id } = useParams();
  const { IMG_URL } = useContext(Context);
  const [order, setOrder] = useState([]);
  const [orderStatus, setorderStatus] = useState([]);
  const getData = async () => {
    const res = await GetOrderDetails(id);
    setOrder(res.data);
    {
      const res = await OrderStatus();
      setorderStatus(res.data);
    }
  };

  useEffect(() => {
    if (id) {
      setValue("order_id", id);
      getData();
    }
  }, [id]);

  //

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    // console.log(data);
    const res = await ReturnOrder(data);
    if (res?.success) {
      setModalSuccessShow(true);
      navigate("/your-orders");
    } else {
      // setErrorMessage(res?.message?.errors?.reason?.msg);
    }
  };
  return (
    <>
      <section className="Order">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="Main-Order-Card">
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12}>
                <div className="Content">
                  <div className="order-content">
                    <div className="Content-main">
                      <h4 className="commmontext">
                        {" "}
                        Invoice Number : {order?.invoice_number}
                      </h4>
                      {/* <p className="small-text">
                        2" 40 Meter, 38 Micron, Per Box 72 Nos
                      </p> */}
                      <p className="small-text">Order Number: {order?.id}</p>
                      <p className="small-text">
                        Payment: {order?.payment_mode}
                      </p>
                      <div className="price-main">
                        <p className="commmontext price me-4">
                          Total: ₹ {order?.total}
                        </p>
                        <p className="main-price">
                          ₹ {order?.total_mrp} <br />
                          (Inclusive of all taxes)
                        </p>
                      </div>
                      {/* <p className="small-text mt-2">
                        Total Additional Charges: ₹ 50
                      </p> */}
                    </div>
                    <div className="view">
                      {/* <Link to={"/b2bbulkorder"}>
                        <p className="small-text">View Product</p>
                      </Link> */}
                      <Link to={`/view-order-details/${order?.id}`}>
                        <p className="small-text">View Order details</p>
                      </Link>
                    </div>
                  </div>

                  <div className="steps">
                    {orderStatus?.map((val, index) => (
                      <React.Fragment key={val?.id}>
                        <div
                          className={`step step${val?.id} ${
                            order.order_status_id >= val?.id
                              ? "completed"
                              : "incompleted"
                          }`}
                        >
                          <div className="step-name">
                            <div
                              className={`circle me-3 ${
                                order.order_status_id >= val?.id
                                  ? "circle-fill"
                                  : ""
                              }`}
                            >
                              <FontAwesomeIcon
                                icon={Order_Status_icon[`${val?.id}`]}
                              />
                            </div>
                            <p className="small-text">{val?.name}</p>
                          </div>
                          <p className="sub"></p>
                        </div>
                        {val?.id < Order_Status.delivered && (
                          <div
                            className={`border-card ${
                              order.order_status_id >= val?.id
                                ? "border-card-before-after"
                                : "border-card-before-after-incomplete"
                            }`}
                          ></div>
                        )}
                      </React.Fragment>
                    ))}
                    {/* {[
                      {
                        step: 1,
                        name: "Order Placed",
                        icon: faNoteSticky,
                        date: "9 Jun, 2024, 11:45 am",
                      },
                      {
                        step: 2,
                        name: "Processing",
                        icon: faBox,
                        date: "11 Jun, 2024",
                      },
                      {
                        step: 3,
                        name: "Shipping",
                        icon: faTruckFast,
                        date: "10 Jun, 2024",
                      },
                      {
                        step: 4,
                        name: "Delivery On",
                        icon: faBox,
                        date: "11 Jun, 2024",
                      },
                    ].map(({ step, name, icon, date }) => (
                      <React.Fragment key={step}>
                        <div
                          className={`step step${step} ${
                            currentStep >= step ? "completed " : "incompleted"
                          }`}
                        >
                          <div className="step-name">
                            <div
                              className={`circle me-3 ${
                                currentStep >= step ? "yellow" : ""
                              }`}
                            >
                              <FontAwesomeIcon icon={icon} />
                            </div>
                            <p className="small-text">{name}</p>
                          </div>
                          <p className="sub">{date}</p>
                        </div>
                        {step < 4 && (
                          <div
                            className={`border-card ${
                              currentStep >= step
                                ? "border-card-before-after"
                                : "border-card-before-after-incomplete"
                            }`}
                          ></div>
                        )}
                      </React.Fragment>
                    ))} */}
                  </div>
                  <div
                    className="cancellation"
                    onClick={() => setModalShow(true)}
                  >
                    <div className="Main-cancel">
                      <p className="commmontext">Select a reason for return</p>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </div>
                  </div>
                  <div className="text-area">
                    <p className="small-text">
                      Could you tell us a reason for return? (optional)
                    </p>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          label="I dont Want to Buy"
                          placeholder="type a reason"
                          {...register("reason", {})}
                        />
                      </Form.Group>{" "}
                      {errors?.order_return_reason_id && (
                        <sup className="text-danger">
                          {errors?.order_return_reason_id?.message}
                        </sup>
                      )}
                    </Form>
                  </div>
                  <div className="Btn-Cancel">
                    <Link to={"/order"}>
                      <Button_Common
                        text="Cancel"
                        className="cancel me-5 small-text"
                      />
                    </Link>
                    <Button_Common
                      text="Submit"
                      className="submit small-text"
                      onClick={handleSubmit(onSubmit)}
                      // onClick={() => setModalSuccessShow(true)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <SelectOptionReturn
          show={modalShow}
          onHide={() => setModalShow(false)}
          getValues={getValues}
          register={register}
          setValue={setValue}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
        />
        <Successfull_Modal
          show={modalSuccessShow}
          onHide={() => setModalSuccessShow(false)}
          message={"Order Return Successfully!"}
        />
      </section>
    </>
  );
};

export default ReturnOrderComponent;
