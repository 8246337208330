import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getHelpCategory = async () => {
  try {
    return await getData(`/without-login/all-help-category`);
  } catch (error) {
    console.log(error);
  }
};

export const getHelps = async (id) => {
  try {
    return await getData(`/without-login/allfaq/${id}`);
  } catch (error) {
    console.log(error);
  }
};
