import React, { useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import Submit_Button from "../CommonComponent/Button_Common/Submit_button";
import PostSubmission from "../CommonComponent/Common_Modal/PostSubmission/PostSubmission";
import {
  getCustomerTapROD,
  postCustomerTapROD,
} from "../../utils/apis/customize_tap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
const OrderDetail = ({ handlePrevious, handleNext, Cookies }) => {
  const [modalShow, setModalShow] = React.useState(false);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    data.c_t_c_info_id = Cookies.get("c_t_c_info_id");

    const res = await postCustomerTapROD(data);

    if (res?.success) {
      reset(res.data);
      setModalShow(true);
    } else {
      console.error(res);
    }
  };

  const getData = async (id) => {
    const res = await getCustomerTapROD(id);
    reset(res.data);
  };

  useEffect(() => {
    if (Cookies.get("c_t_c_info_id")) {
      getData(Cookies.get("c_t_c_info_id"));
    }
  }, []);
  return (
    <section className="OrderDetail">
      <p className="sub-title mt-4">
        Single Time Requirement or Repeated Order*
      </p>

      <Form>
        <div className="order-main">
          <Form.Check
            type="radio"
            name="repeat"
            {...register("repeat", {
              required: "repeat is required",
            })}
            className={classNames("sub-title", {
              "is-invalid": errors?.repeat,
            })}
            onChange={() => {
              setValue("repeat", "Single Time");
              reset(getValues());
            }}
            value="Single Time"
            label="Single Time"
          />
          <Form.Check
            type="radio"
            name="repeat"
            {...register("repeat", {
              required: "repeat is required",
            })}
            className={classNames("sub-title", {
              "is-invalid": errors?.repeat,
            })}
            onChange={() => {
              setValue("repeat", "Repeated Time");
              reset(getValues());
            }}
            value="Repeated Time"
            label="Repeated Time"
          />
        </div>
      </Form>
      {getValues("repeat") == "Repeated Time" && (
        <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
          <Form.Label className="sub-title">Repeat Order Frequency*</Form.Label>
          <Form.Control
            type="number"
            name="nxt_after_days"
            {...register("nxt_after_days", {
              required: "nxt_after_days is required",
            })}
            className={classNames("sub-title", {
              "is-invalid": errors?.nxt_after_days,
            })}
            placeholder="After how many days do you need the next order?"
          />
        </Form.Group>
      )}

      <div className="Product">
        <p className="sub-title mt-4">Specific Requirements*</p>
        <Form className="main-product">
          <div className="radio-btn-input-holder mt-4">
            <Row>
              <Col xxl={3}>
                <Form.Check
                  type="radio"
                  name="specific_req"
                  {...register("specific_req", {
                    required: "specific_req is required",
                  })}
                  className={classNames("sub-title", {
                    "is-invalid": errors?.specific_req,
                  })}
                  value="38 Micron"
                  label="38 Micron"
                />
              </Col>
              <Col xxl={3}>
                <Form.Check
                  type="radio"
                  name="specific_req"
                  {...register("specific_req", {
                    required: "specific_req is required",
                  })}
                  className={classNames("sub-title", {
                    "is-invalid": errors?.specific_req,
                  })}
                  value="40 Micron"
                  label="40 Micron"
                />
              </Col>
              <Col xxl={3}>
                <Form.Check
                  type="radio"
                  name="specific_req"
                  {...register("specific_req", {
                    required: "specific_req is required",
                  })}
                  className={classNames("sub-title", {
                    "is-invalid": errors?.specific_req,
                  })}
                  value="42 Micron"
                  label="42 Micron"
                />
              </Col>
              <Col xxl={3}>
                <Form.Check
                  type="radio"
                  name="specific_req"
                  {...register("specific_req", {
                    required: "specific_req is required",
                  })}
                  className={classNames("sub-title", {
                    "is-invalid": errors?.specific_req,
                  })}
                  value="50 Micron"
                  label="50 Micron"
                />
              </Col>
              <Col xxl={3}>
                <Form.Check
                  type="radio"
                  name="specific_req"
                  {...register("specific_req", {
                    required: "specific_req is required",
                  })}
                  className={classNames("sub-title", {
                    "is-invalid": errors?.specific_req,
                  })}
                  value="54 Micron"
                  label="54 Micron"
                />
              </Col>
            </Row>
          </div>
        </Form>
      </div>

      <div className="apply_now_btn">
        <Button_Common
          onClick={handlePrevious}
          className="back-btn me-3 small-text back"
          text="BACK"
        >
          Back
        </Button_Common>

        <Button_Common
          type="button"
          // onClick={handleNext}
          onClick={handleSubmit(onSubmit)}
          className="tabs-btn small-text orange"
          text="Submit"
        >
          Next
        </Button_Common>

        {/* <Submit_Button
          // onClick={() => setModalShow(true)}
          // type="submit"
          // onClick={handleNext}
          type="button"
          onClick={handleSubmit(onSubmit)}
          className="tabs-btn small-text orange"
          text="Submit"
        >
          Next
        </Submit_Button> */}
      </div>
      <div className="Customer"></div>
      <PostSubmission
        Cookies={Cookies}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </section>
  );
};

export default OrderDetail;
