import React, { useContext, useEffect, useState } from "react";
import "./HotDeals.css";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import ProductCardSale from "../CommonComponent/ProductCardSale/ProductCardSale";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import { getSale } from "../../utils/apis/home";
import CountdownTimer from "../CommonComponent/CountUp/CountUp";
import { product_coupon, UserType } from "../../utils/common";

function HotDeals() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Hot Deals", link: "" },
    ,
  ]);

  const [showMore, setShowMore] = useState(false);

  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const product = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
  ];

  const visibleProducts = showMore ? product : product.slice(0, 6);

  //   -------------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser, usertype } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getSale();
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="salelive_section">
        <Container>
          <div className="salelive_main_div">
            <div className="toptext_div ">
              <h2 className="title-main mb-3">
                <span className="main-title-border ">HOT DEALS</span> ON
              </h2>

              <CountdownTimer endDate={data?.Sale?.end_date} />

              {/*  */}

              {/* <div className="coundown_div mb-3">
                <div className="counter">
                  <div className="timer">
                    <CountUp start={0} end={0} duration={10} decimals={0} />
                    <CountUp start={0} end={0} duration={10} decimals={0} />
                  </div>
                  <div className="timer dotts">:</div>
                  <div className="timer">
                    <CountUp start={0} end={0} duration={10} decimals={0} />
                    <CountUp start={0} end={10} duration={10} decimals={0} />
                  </div>
                  <div className="timer dotts">:</div>
                  <div className="timer">
                    <CountUp start={0} end={0} duration={10} decimals={0} />
                    <CountUp start={0} end={60} duration={10} decimals={0} />
                  </div>
                </div>
              </div> */}
            </div>

            <div className="swipe_div_main">
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  486: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1400: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                  1900: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                }}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.products?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <ProductCardSale
                      average_rating={item.average_rating}
                      id={item.id}
                      image={item.image}
                      title={item.name}
                      discount_percentage={item.discount_percentage}
                      short_description={item?.short_description}
                      price={
                        usertype == UserType.B2BCustomer
                          ? item?.btb_price
                          : item?.btc_price
                      }
                      mrp={item?.mrp}
                      save={
                        product_coupon.Percentage ===
                        item?.product_cuppon?.coupon_type
                          ? item?.product_cuppon?.discount + " %"
                          : "₹ " + item?.product_cuppon?.total_amount
                      }
                      // image={item.image}
                      // title={item.title}
                      // size={item.size}
                      // saleprice={item.saleprice}
                      // realprice={item.realprice}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </Container>
        {/*  <section className="hoteals_section"><Container>
          <div className="hotdeals_maindiv">
            <div>
              <Breadcrumbs items={breadcrumbItems} />
            </div>
            <div className="topplate_div mb-4">
              <h4 className="dealtitle merriweather-bold mb-0">Hot Deals</h4>
              <p
                className="showtext mb-0 text-p"
                onClick={() => setShowMore(!showMore)}
              >
                {" "}
                {showMore ? "Show Less" : "Show More"}{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="angleiconn ms-1  "
                />
              </p>
            </div>

            {!showMore && (
              <div className="swipe_div_mainss">
                <Swiper
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    486: {
                      slidesPerView: 1.5,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    992: {
                      slidesPerView: 2.8,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    1400: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    1900: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                  }}
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {visibleProducts.map((item, index) => (
                    <SwiperSlide key={index}>
                      <ProductCardSale
                        image={item.image}
                        title={item.title}
                        size={item.size}
                        saleprice={item.saleprice}
                        realprice={item.realprice}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="swiperoverlay">
                  <div
                    className="back-btn"
                    onClick={() => handleMainSliderClick("prev")}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
                  </div>
                </div>
                <div className="swiperoverlay2">
                  <div
                    className="next-btn"
                    onClick={() => handleMainSliderClick("next")}
                  >
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="icon-main"
                    />
                  </div>
                </div>
              </div>
            )}
            {showMore && (
              <div>
                <Row>
                  {visibleProducts.map((item, index) => (
                    <Col xxl={3} xl={4} lg={6} md={6} sm={12} className="mb-3">
                      <div>
                        <ProductCardSale
                          image={item.image}
                          title={item.title}
                          size={item.size}
                          saleprice={item.saleprice}
                          realprice={item.realprice}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </div>
        </Container> </section> */}
      </section>
    </>
  );
}

export default HotDeals;
