import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Common_Button from "../../CommonComponent/Button_Common/Button_Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import OTP_Modal from "../OTP_Modal/OTP_Modal";
import {
  ForgetPasswordEmailOtpSend,
  ForgetPasswordOtpSend,
} from "../../../utils/apis/otp/otp";
import { useForm } from "react-hook-form";
import { RegxExpression } from "../../../utils/common";
import classNames from "classnames";
function Forgot_Password(props) {
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    register,
  } = useForm({});

  const onSubmit = async (d) => {
    // console.log(d);
    // // if (RegxExpression.email.test(getValues("email"))) {
    // //   console.log(getValues("email"));
    const res = await ForgetPasswordOtpSend(d.email_or_contact);

    // console.log(res);
    if (res.success) {
      clearErrors();
      navigate(`/otp/${d.email_or_contact}`);
    } else {
      setError("email_or_contact", {
        message: res.message,
      });
    }
    // // } else {
    // //   setError("email", {
    // //     message: "Enter Valid Email",
    // //   });
    // // }
  };

  return (
    <>
      <div className="Modal-holder">
        <div className="LogIn">
          <Container>
            <div className="row bg-color me-0 ms-0 justify-content-center LogIn-holder">
              <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7">
                <div className="row justify-content-center">
                  <div className="col-xl-12 col-lg-12">
                    <div className="log_in_form">
                      <div className="Main-starting">
                        <div className="login-box-button">
                          <p className="commmontext">Reset Your Password</p>
                        </div>

                        <div className="text-holder">
                          <p className="note-text sub-title">
                            You will receive an email from us requesting you to
                            Reset your password.
                          </p>
                        </div>

                        <Form>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Label className="small-text">
                              Email / Mobile Number
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Email / Mobile Number"
                              name="email_or_contact"
                              {...register("email_or_contact", {
                                required: "Email / Mobile Number required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.email_or_contact,
                                // "is-valid": getValues("email_or_contact"),
                              })}
                            />
                            {errors?.email_or_contact && (
                              <sup className="text-danger">
                                {errors?.email_or_contact?.message}
                              </sup>
                            )}
                          </Form.Group>

                          <div className="btn-holder">
                            <Link to="/">
                              <Common_Button
                                className="button button--webcoderskull button--border-thick button--text-medium cancle-btn sub-title"
                                text="Cancel"
                              />
                            </Link>

                            <Common_Button
                              className="button button--webcoderskull button--border-thick button--text-medium login-btn sub-title submit orange"
                              text="Submit"
                              onClick={handleSubmit(onSubmit)}
                            />
                          </div>
                        </Form>

                        <Link to="/">
                          <div className="back_to_span">
                            <p>
                              Back to Home
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                className="back-arrow"
                              />
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Forgot_Password;
