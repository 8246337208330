import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getWhyCMJ = async () => {
  try {
    return await getData(`/without-login/why/why-cmj`);
  } catch (error) {
    console.log(error);
  }
};

export const getWhyOurMission = async () => {
  try {
    return await getData(`/without-login/about-us/our-mission`);
  } catch (error) {
    console.log(error);
  }
};
