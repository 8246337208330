import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

import { Context, variant } from "../../context";
import { useContext } from "react";

import { getProductDetail, getVariant } from "../Product/Product";

export const loginCart = async () => {
  let cartItems = [];
  const existingCart = Cookies.get("cmj_cart");
  if (existingCart) {
    cartItems = JSON.parse(existingCart);
  }
  if (cartItems.length > 0) {
    await Promise.all(
      cartItems.map(async (value) => {
        const data = await postData(`/cart/add`, {
          product_id: value?.product_id,
          p_a_v_id: value?.p_a_v_id,
          p_a_v_a_v_id: value?.p_a_v_a_v_id,
          quantity: value?.quantity,
          type: value?.type,
          moq_id: value?.moq_id,
          coupon_status: value?.coupon_status,
        });
      })
    );
  }
  await Cookies.remove("cmj_cart");
};

export const AddToCart = async (d) => {
  try {
    if (Cookies.get("cmj_security")) {
      // User is logged in, handle adding to cart via API
      const res = await postData(`/cart/add`, {
        product_id: d?.product_id,
        p_a_v_id: d?.p_a_v_id,
        p_a_v_a_v_id: d?.p_a_v_a_v_id,
        quantity: d?.quantity,
        type: d?.type,
        moq_id: d?.moq_id,
        coupon_status: d?.coupon_status,
      });
      return res;
    } else {
      // User is not logged in, handle adding to cart locally
      let cartItems = [];

      const existingCart = Cookies.get("cmj_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      const existingItemIndex = cartItems.findIndex(
        (item) =>
          item.product_id === d?.product_id &&
          item.p_a_v_id === d?.p_a_v_id &&
          item.p_a_v_a_v_id === d?.p_a_v_a_v_id
      );

      if (existingItemIndex === -1) {
        // New item, add to cart
        const newItem = { ...d };
        newItem.id = uuidv4();
        newItem.quantity = d?.quantity;
        newItem.min_quantity = d?.quantity;
        newItem.moq_id = d?.moq_id;
        cartItems.push(newItem);
        Cookies.set("cmj_cart", JSON.stringify(cartItems));
        return { success: true, data: newItem };
      } else {
        // Item exists, increment quantity
        cartItems[existingItemIndex].quantity++;
        Cookies.set("cmj_cart", JSON.stringify(cartItems));
        return { success: true, data: cartItems[existingItemIndex] };
      }
    }
  } catch (error) {
    console.error("Error adding to cart:", error);
    return { success: false, message: "Error adding to cart" };
  }
};

export const CartQuantity = async (id, type, signin, setCart) => {
  try {
    if (Cookies.get("cmj_security")) {
      if (id) {
        await postData(`/cart/quantity`, {
          cart_id: id,
          type: type,
        });
        updateCartCount(signin, setCart);
      }

      // return true;
    } else {
      // console.log(id);
      // console.log(type);
      let cartItems = [];

      const existingCart = Cookies.get("cmj_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      // console.log(id);

      const existingItemIndex = cartItems.findIndex((item) => item.id === id);
      if (existingItemIndex === -1) {
      } else {
        if (cartItems[existingItemIndex]?.quantity === 1 && type === "minus") {
          cartItems.splice(existingItemIndex, 1);
        } else {
          if (type === "minus") {
            cartItems[existingItemIndex].quantity -= 1;
          } else if (type === "add") {
            cartItems[existingItemIndex].quantity += 1;
          } else if (type === "delete") {
            cartItems.splice(existingItemIndex, 1);
          }
        }

        Cookies.set("cmj_cart", JSON.stringify(cartItems));

        // console.log(type);
      }
      updateCartCount(signin, setCart);
    }
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const getCart = async () => {
  try {
    return await getData(`/cart/show`);
  } catch (error) {
    console.log(error);
  }
};

export const getCartCheck = async (data) => {
  try {
    if (Cookies.get("cmj_security")) {
      const queryParams = new URLSearchParams(data).toString();
      // console.log("queryParams", queryParams);
      return await getData(`/cart/getCart?${queryParams}`);
    } else {
      let cartItems = [];
      const existingCart = Cookies.get("cmj_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }
      const existingItemIndex = cartItems.findIndex(
        (item) =>
          item.product_id === data?.product_id &&
          item.p_a_v_id === data?.p_a_v_id &&
          item.p_a_v_a_v_id === data?.p_a_v_a_v_id
      );
      // console.log("existingItemIndex", existingItemIndex);

      if (existingItemIndex === -1) {
        return { success: false, cartItem: cartItems[existingItemIndex] };
      } else {
        // console.log(
        //   "cartItems[existingItemIndex]",
        //   cartItems[existingItemIndex]
        // );

        return { success: true, cartItem: cartItems[existingItemIndex] };
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCartCount = async (
  signin,
  setCart
  // setCartCount = 0
) => {
  if (signin) {
    try {
      const cartItems = await getData(`/cart/show`);
      setCart(cartItems?.data || []);
      // setCartCount(cartItems?.data?.length || 0);
    } catch (error) {
      console.log(error);
    }
  } else if (Cookies.get("cmj_cart")) {
    // setCartCount(JSON.parse(Cookies.get("cmj_cart")).length);
    setCart(JSON.parse(Cookies.get("cmj_cart") || []));
  }
};

export const CartStatus = async (id, data, signin, setCart) => {
  try {
    if (signin) {
      const res = await postData(`/cart/status/${id}`, data);
    } else {
      let cartItems = [];

      const existingCart = Cookies.get("cmj_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      const existingItemIndex = cartItems.findIndex((item) => item.id === id);
      if (existingItemIndex === -1) {
      } else {
        cartItems[existingItemIndex].status = data.status;
        Cookies.set("cmj_cart", JSON.stringify(cartItems));
      }
    }

    updateCartCount(signin, setCart);
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const getSeletedCart = async () => {
  try {
    return await getData(`/cart/seleted`);
  } catch (error) {
    console.log(error);
  }
};

export const BuyNow = async (d) => {
  try {
    let cartItems = [];
    await Cookies.remove("cmj_buy_now");
    const existingCart = await Cookies.get("cmj_buy_now");
    if (existingCart) {
      cartItems = JSON.parse(existingCart);
    }

    const existingItemIndex = cartItems.findIndex(
      (item) =>
        item.product_id === d?.product_id &&
        item.p_a_v_id === d?.p_a_v_id &&
        item.p_a_v_a_v_id === d?.p_a_v_a_v_id
    );

    if (existingItemIndex === -1) {
      const newItem = { ...d };
      newItem.id = uuidv4();
      // console.log(newItem);

      cartItems.push(newItem);
    } else {
      cartItems[existingItemIndex].quantity++;
    }

    Cookies.set("cmj_buy_now", JSON.stringify(cartItems));

    return true;
  } catch (error) {
    console.log(error);
  }
};
