import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../../CommonComponent/Breadcrumbs/Breadcrumbs";
import AddedCard from "../../CommonComponent/AddedCard/AddedCard";
import "./CartComponent.css";
import Form from "react-bootstrap/Form";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import LogOut from "../../CommonComponent/Common_Modal/LogOut/LogOut";
import { Link, useNavigate } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CartStatus, getCart } from "../../../utils/apis/addToCart/addToCart";
import { Context } from "../../../utils/context";
import { useForm } from "react-hook-form";
const CartComponent = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Account", link: "" },
    { text: "Cart", link: "/cart", className: "active" },
  ]);

  //   const cart = [
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
  //       name: "Brown Tape",
  //       text: "2 40 Meter,38 Micron, Per Box 72 Nos",
  //       quantity: "1",
  //       price: "₹.408",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
  //       name: "Brown Tape",
  //       text: "2 40 Meter,38 Micron, Per Box 72 Nos",
  //       quantity: "1",
  //       price: "₹.408",
  //     },
  //   ];
  //   const [modalShow, setModalShow] = React.useState(false);

  //   const [selectedItems, setSelectedItems] = useState(cart.map(() => false));

  //   const handleSelectAll = (e) => {
  //     setSelectedItems(selectedItems.map(() => e.target.checked));
  //   };

  //   const handleSelectItem = (index) => {
  //     setSelectedItems((prevSelectedItems) =>
  //       prevSelectedItems.map((item, idx) => (idx === index ? !item : item))
  //     );
  //   };

  // ------------------------------------------------------------------------------------------------
  const navigate = useNavigate();
  const {
    IMG_URL,
    signin,
    triggerCartUpdate,
    Cookies,
    cart,
    setCart,
  } = useContext(Context);

  //   const [cart, setCart] = useState([]);
  //   const getCartQuantity = async (id, type) => {
  //     setDiscount(0);
  //     setCoupon();
  //     setCouponStatus(false);
  //     setError("code", "");

  //     await CartQuantity(id, type);
  //     if (Cookies.get("mansa_nutri_cart")) {
  //       if (JSON.parse(Cookies.get("mansa_nutri_cart")).length === 0) {
  //         navigate("/cart");
  //       }
  //       setCart(JSON.parse(Cookies.get("mansa_nutri_cart")));
  //       withoutCart();
  //     }
  //     if (signin) {
  //       await cartGet();
  //     }
  //     triggerCartUpdate();
  //   };

  // console.log("cart", cart);

  const cartGet = async () => {
    const res = await getCart();
    if (res?.success) {
      if (res?.data?.length === 0) {
        navigate("/cart");
      }
      setCart(res?.data);
    }
  };

  const withoutCart = async () => {
    if (Cookies.get("cmj_cart")) {
      if (JSON.parse(Cookies.get("cmj_cart")).length === 0) {
        navigate("/cart");
      }
      await setCart(JSON.parse(Cookies.get("cmj_cart")));
    }
  };

  useEffect(() => {
    if (signin) {
      cartGet();
    } else {
      withoutCart();
    }
  }, [Cookies.get("mansa_nutri_cart"), signin]);

  useEffect(() => {
    TotalCalculations();
  }, [cart]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [selete, setSelete] = useState([]);

  const TotalCalculations = async () => {
    let total_amount = 0;
    let discount = 0;
    let total_tax = 0;
    let shipping = 0;
    let total = 0;
    let coupon_discount = 0;

    const selete = [];
    for (const iterator of cart) {
      // console.log(iterator);

      if (iterator.status == 1) {
        selete.push(iterator.id);
        // const quantity =
        //   iterator?.type === "single"
        //     ? iterator?.quantity
        //     : iterator?.quantity * iterator?.box_unit;

        const quantity = iterator?.quantity;

        // total_amount
        const amount = Number(iterator.price) * Number(quantity);
        total_amount = total_amount + amount;
        // total tax

        const tax = (amount / 100) * Number(iterator.tax_percentage);

        total_tax = total_tax + tax;

        coupon_discount = coupon_discount + quantity * iterator.coupon_discount;
      }
    }
    setSelete(selete);
    total = total_amount + total_tax;

    total = total - discount;

    total = total + shipping;

    // Math.round(total);

    setValue("total_amount", total_amount?.toFixed(2));
    setValue("discount", discount?.toFixed(2));
    setValue("coupon_discount", coupon_discount?.toFixed(2));
    setValue("total_tax", total_tax?.toFixed(2));
    setValue("shipping", shipping?.toFixed(2));
    setValue("total", +Math.round(total.toFixed(2)));
    reset(getValues());
  };

  const SelectAll = async (e) => {
    for (const iterator of cart) {
      CartStatus(
        iterator?.id,
        {
          status: e.target.checked,
        },
        signin,
        setCart
      );
    }
  };
  // console.log(cart);

  return (
    <>
      <section className="Cart">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <Row>
            <Col xxl={9} xl={8} lg={12} md={12}>
              <div className="delete-btn">
                <Form>
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    className="small-text"
                    checked={selete?.length == cart?.length}
                    onClick={SelectAll}
                    // onChange={handleSelectAll}
                    // checked={selectedItems.every(Boolean)}
                  />
                </Form>
                {/* <div
                  className="main-circle"
                  //  onClick={() => setModalShow(true)}
                >
                  <FontAwesomeIcon icon={faTrash} className="delete-icon" />
                </div> */}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col xxl={9} xl={8} lg={12} md={12}>
              {cart?.map((item, index) => (
                <div className="" key={index}>
                  <AddedCard
                    box_unit={item.box_unit}
                    mrp={item.mrp}
                    moq_id={item?.moq_id}
                    min_quantity={item.min_quantity}
                    image={item.image}
                    short_description={item.short_description}
                    name={item.name}
                    quantity={Number(item?.quantity)}
                    discount_percentage={item.discount_percentage}
                    type={item?.type}
                    id={item?.id}
                    price={item.price}
                    isSelected={item.status}
                    // onSelect={() => handleSelectItem(index)}
                  />
                </div>
              ))}
            </Col>

            <Col xxl={3} xl={4} lg={5} md={6}>
              <div className="Order-Summary">
                <h1 className="sub-title">Order Summary</h1>
                <div className="Total t-main mt-4">
                  <p className="sub-title">Total amount</p>
                  <p className="sub-title">₹{getValues("total_amount")}</p>
                </div>
                {/* <div className="Total  t-main mt-2">
                  <p className="sub-title">Coupon Discount</p>
                  <p className="sub-title">- ₹{getValues("coupon_discount")}</p>
                </div> */}
                <div className="Total  t-main mt-2">
                  <p className="sub-title">Discount</p>
                  <p className="sub-title">- ₹{getValues("discount")}</p>
                </div>
                <div className="Total  t-main mt-2">
                  <p className="sub-title">Tax</p>
                  <p className="sub-title">₹{getValues("total_tax")}</p>
                </div>
                <div className="Total mt-2">
                  <p className="sub-title">Shipping</p>
                  <p className="sub-title">₹{getValues("shipping")}</p>
                </div>
                <div className="Total mt-4">
                  <p className="sub-title t2">Total</p>
                  <p className="sub-title t2">₹{getValues("total")}</p>
                </div>
                {signin && cart.length ? (
                  <Link to="/checkout">
                    <div className="main-btn mt-4">
                      <Button_Common
                        className="check small-text"
                        text="Proceed to Checkout"
                      />
                    </div>
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <LogOut show={modalShow} onHide={() => setModalShow(false)} /> */}
    </>
  );
};

export default CartComponent;
