// import React from "react";
import "./HomeBanner.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import { getBanners, getHomeSideBanner } from "../../../utils/apis/home";
import Verticalslider from "./Verticalslider";
const HomeBanner = () => {
  //   const banners = [
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/HomePage/Banner/home1.png",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/HomePage/Banner/home1.png",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/HomePage/Banner/home1.png",
  //     },
  //   ];

  //   -------------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const getData = async () => {
    const res = await getBanners();
    setData(res?.data);

    {
      const res = await getHomeSideBanner();
      setData2(res?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="HomeBanner">
        <Container>
          <Row>
            <Col xxl={8} xl={8} lg={8} className="">
              <Swiper
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                clickable={true}
              >
                {data?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="Main-Banner">
                      <img src={IMG_URL + item.image} className="banner" />
                      {/* <div className="Button-Explore">
                        <Button_Common
                          text="Explore Now"
                          icon={faArrowRight}
                          className="explore explore-btn"
                        />
                      </div> */}
                    </div>
                  </SwiperSlide>
                ))}
                {/* {banners.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="Main-Banner">
                      <img src={item.image} className="banner" />
                      <div className="Button-Explore">
                        <Button_Common
                          text="Explore Now"
                          icon={faArrowRight}
                          className="explore explore-btn"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))} */}
              </Swiper>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              {/* <Swiper
                pagination={true}
                slidesPerView={2}
                modules={[Pagination]}
                direction="vertical"
                className="mySwiper"
              >
                {data?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="Main-Banner">
                      <img src={IMG_URL + item.image} className="banner" />
                     
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper> */}

              <Verticalslider data={data2} IMG_URL={IMG_URL} />

              {/* <div className="side-images">
                <img    src={IMG_URL + data[0]?.image}
                  className="banner2 "
                />
              </div>
              <div className="side-images mt-4">
                <img  src={IMG_URL + (data[1]?.image || data[0]?.image)}
                  className="banner2"
                />
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HomeBanner;
