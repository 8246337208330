import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Rating } from "react-simple-star-rating";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import Bottom_Successfull_Modal from "../CommonComponent/Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import PostSubmission from "../CommonComponent/Common_Modal/PostSubmission/PostSubmission";

const CustomerFeedback = ({ getValues }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalPostSubmissionShow, setModalPostSubmissionShow] = React.useState(false);


  useEffect(() => {
    let timer;
    if (modalShow) {
      timer = setTimeout(() => {
        setModalShow(false);
        setModalPostSubmissionShow(true);
      }, 2000);
    }

    return () => clearTimeout(timer); // Cleanup the timer
  }, [modalShow]); // Dependency array

  return (
    <>
      <section className="CustomerFeedback">
        <div className="Feedback">
          <h1 className="title-customer">Customer Feedback</h1>
          <div className="Main-Feed">
            <p className="sub-title">How did you hear about us?</p>
            <FloatingLabel
              controlId="floatingTextarea"
              label="e.g., Online Search, Social Media, and Referral"
              className="sub"
            >
              <Form.Control as="textarea" placeholder="Leave a comment here" />
            </FloatingLabel>
            <div className="feed-rating mt-4">
              <p className="sub-title">
                Rate Your Previous Experience with you
              </p>
              <Rating />
            </div>

            <FloatingLabel
              controlId="floatingTextarea2"
              label="Any suggestions to improve our service"
              className="sub"
            >
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                style={{ height: "100px" }}
              />
            </FloatingLabel>

            <div className="submit">
              <Button_Common
                onClick={() => setModalShow(true)}
                text="Submit feedback"
                className="submit small-text orange"
              />
            </div>
          </div>
        </div>
      </section>

      <Bottom_Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Customer feedback sent Successfully!"}
      />

      <PostSubmission show={modalPostSubmissionShow} onHide={() => setModalPostSubmissionShow(false)} />
    </>
  );
};

export default CustomerFeedback;
