import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ProductInformation.css";
import Table from "react-bootstrap/Table";
import { Rating } from "react-simple-star-rating";
import { Context } from "../../../utils/context";
const ProductInformation = ({ getValues, ...props }) => {
  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  // Optinal callback functions
  const onPointerEnter = () => {
    // console.log("Enter");
  };
  const onPointerLeave = () => {
    // console.log("Leave");
  };
  const onPointerMove = (value, index) => {
    // console.log(value, index);
  };

  //   ----------------------------------------------------------------
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  return (
    <>
      <section className="ProductInformation">
        <Container>
          <h1 className="title-main">
            <span className="main-title-border">Product</span> information
          </h1>

          {htmlToReactParser.parse(getValues("product_info")) || ""}
          {/* <Row className="mt-5">
            <Col xxl={6} xl={6} lg={12} md={12}>
              <p className="text-p">Technical Details</p>
              <div className="product-table">
                <Table className="tableDdemo">
                  <tbody>
                    <tr className="text-p">
                      <td>Brand</td>
                      <td>CMJ INDUSTRIES</td>
                    </tr>
                    <tr className="text-p">
                      <td>Colour</td>
                      <td>Transparent Tape</td>
                    </tr>
                    <tr className="text-p">
                      <td>Material</td>
                      <td>Biaxially Oriented Polypropylene</td>
                    </tr>
                    <tr className="text-p">
                      <td>Number of Items</td>
                      <td>4</td>
                    </tr>
                    <tr className="text-p">
                      <td>Recommended Uses For Product</td>
                      <td>Packaging</td>
                    </tr>
                    <tr className="text-p">
                      <td>Special Feature</td>
                      <td>Heat,Transparent</td>
                    </tr>
                    <tr className="text-p">
                      <td>Surface Recommendation</td>
                      <td>Cardboard</td>
                    </tr>
                    <tr className="text-p">
                      <td>Size</td>
                      <td>3 Inch 100 Meter Pack of 04</td>
                    </tr>
                    <tr className="text-p">
                      <td>Compatible Material</td>
                      <td>Industrial, Packaging, Bopp</td>
                    </tr>
                    <tr className="text-p">
                      <td>Manufacturer</td>
                      <td>
                        CMJ Industries Pune, Manufacturer All Types of Self
                        Adhesive Bopp Tapes, Headquarters / Factory CMJ
                        INDUSTRIES Gat No 1582, Bhangre Complex, Shop No-13,
                        Near Swami Hotel, Chikhali Pune-411062 Landmark :-Near
                        CMJ Tower Manufacturer All Types of Self Adhesive Bopp
                        Tapes, Transparent Tape, Brown Tape, Packaging Tape.
                        Customer Care :- cmjindustries13@gmail.com Support
                        Email:- cmjsupport@gmail.com HelpLine:-8806 5880 6500
                        24/7 Customer support
                      </td>
                    </tr>
                    <tr className="text-p">
                      <td>Country of Origin</td>
                      <td>India</td>
                    </tr>
                    <tr className="text-p">
                      <td>Model number</td>
                      <td>TRN3" 100 Meter Pack of 04</td>
                    </tr>
                    <tr className="text-p">
                      <td>Item Weight</td>
                      <td>170 g</td>
                    </tr>
                    <tr className="text-p">
                      <td>Product Dimensions</td>
                      <td>7 x 7 x 7 cm; 170 g</td>
                    </tr>
                    <tr className="text-p">
                      <td>Item model number</td>
                      <td>TRN3" 100 Meter Pack of 04</td>
                    </tr>
                    <tr className="text-p">
                      <td>Item Heigh</td>
                      <td>7 Centimeters</td>
                    </tr>
                    <tr className="text-p">
                      <td>Item Width</td>
                      <td>7 Centimeters</td>
                    </tr>
                    <tr className="text-p">
                      <td>Included Components</td>
                      <td>Self-Adhesive Bopp Packaging Tape</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={12} md={12}>
              <p className="text-p">Additional Information</p>
              <Table className="tableDdemo mt-3">
                <tbody>
                  <tr className="text-p">
                    <td>ASIN</td>
                    <td>B0BSZY4X38</td>
                  </tr>
                  <tr className="text-p">
                    <td>Customer Reviews</td>
                    <td>
                      <div className="star">
                        <p className="no">3.5</p>
                        <Rating
                          onClick={handleRating}
                          onPointerEnter={onPointerEnter}
                          onPointerLeave={onPointerLeave}
                          onPointerMove={onPointerMove}
                        />
                        <p className="text-p">15 ratings</p>
                      </div>
                      3.5 out of 5 stars
                    </td>
                  </tr>
                  <tr className="text-p">
                    <td>Best Sellers Rank</td>
                    <td>
                      #22,879 in Industrial & Scientific (See Top 100 in
                      Industrial & Scientific)
                      <br></br>#2,018 in Packaging & Shipping Supplies<br></br>
                      #2,594 in Tape
                    </td>
                  </tr>
                  <tr className="text-p">
                    <td>Date First Available</td>
                    <td>23 January 2023</td>
                  </tr>
                  <tr className="text-p">
                    <td>Manufacturer</td>
                    <td>
                      CMJ Industries Pune, Manufacturer All Types of Self
                      Adhesive Bopp Tapes, Headquarters / Factory CMJ INDUSTRIES
                      Gat No 1582, Bhangre Complex, Shop No-13, Near Swami
                      Hotel, Chikhali Pune-411062 Landmark :-Near CMJ Tower
                      Manufacturer All Types of Self Adhesive Bopp Tapes,
                      Transparent Tape, Brown Tape, Packaging Tape. Customer
                      Care :- cmjindustries13@gmail.com Support Email:-
                      cmjsupport@gmail.com HelpLine:-8806 5880 6500 24/7
                      Customer support
                    </td>
                  </tr>
                  <tr className="text-p">
                    <td>Packer</td>
                    <td>
                      Headquarters / Factory CMJ INDUSTRIES Gat No 1582, Bhangre
                      Complex, Shop No-13, Near Swami Hotel, Chikhali
                      Pune-411062 Landmark :-Near CMJ Tower Manufacturer All
                      Types of Self Adhesive Bopp Tapes, Transparent Tape, Brown
                      Tape, Packaging Tape. Customer Care :-
                      cmjindustries13@gmail.com Support Email:-
                      cmjsupport@gmail.com HelpLine:-8806 5880 6500 24/7
                      Customer support
                    </td>
                  </tr>
                  <tr className="text-p">
                    <td>Importer</td>
                    <td>
                      Headquarters / Factory CMJ INDUSTRIES Gat No 1582, Bhangre
                      Complex, Shop No-13, Near Swami Hotel, Chikhali
                      Pune-411062 Landmark :-Near CMJ Tower Manufacturer All
                      Types of Self Adhesive Bopp Tapes, Transparent Tape, Brown
                      Tape, Packaging Tape. Customer Care :-
                      cmjindustries13@gmail.com Support Email:-
                      cmjsupport@gmail.com HelpLine:-8806 5880 6500 24/7
                      Customer support
                    </td>
                  </tr>
                  <tr className="text-p">
                    <td>Item Dimensions LxWxH</td>
                    <td>7 x 7 x 7 Centimeters</td>
                  </tr>
                  <tr className="text-p">
                    <td>Net Quantity</td>
                    <td>400.00 Meters</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row> */}
        </Container>
      </section>
    </>
  );
};

export default ProductInformation;
