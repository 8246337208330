import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Container } from "react-bootstrap";
import Customer_Support_Details from "./Customer_Support_Details/Customer_Support_Details";
import { Policy } from "../../utils/apis/master/Master";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import { AppSetup } from "../../utils/apis/common/Common";

function Terms_And_Condition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Terms And Condition", link: "" },
    ,
  ]);

  // ----------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await Policy("terms");
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="privacy_section">
        <Container>
          <div>
            <Breadcrumbs items={breadcrumbItems} />
          </div>
          <div className="Privacy_main">
            <div className="privacybanner_div ">
              <img
                className="privacybanner"
                src={process.env.PUBLIC_URL + "/assests/Banner/closebanner.png"}
              />
              <div className="banoverlays">
                <p className="bannertitle">Terms And Condition</p>
              </div>
            </div>
            <div className="bannertext_main_div">
              <div className="bannertext_div mb-3">
                <p className="text-p ">{data?.short_description} </p>
              </div>
              <div className="bannersubtext_div mb-3">
                {htmlToReactParser.parse(data?.description) || ""}
                <h1 className="bigtextt">Terms And Condition</h1>
              </div>

              {/* <div className="bannertext_div mb-3">
                <p className="text-p ">
                  This website is owned and operated by CMJ Industries. These
                  Terms set forth the terms and conditions under which you may
                  use our website and services as offered by us. This website
                  offers visitors products for purchase. By accessing or using
                  the website of our service, you approve that you have read,
                  understood, and agree to be bound by the following terms and
                  conditions along with the terms and conditions as stated in
                  our Privacy Policy (please refer to the Privacy Policy section
                  for more information). We reserve the right to change this
                  Agreement from time to time with/without notice. You
                  acknowledge and agree that it is your responsibility to review
                  this Agreement periodically to familiarize yourself with any
                  modifications. After such modifications, your continued use of
                  this site will constitute acknowledgment and agreement of the
                  modified terms and conditions.
                </p>
              </div>

              <div className="bannersubtext_div mb-3">
                <p className="privecy_subtitle">Usage of the website</p>
                <p className="privcytext ms-sm-4">
                  To use our website and/or receive our services, you must be at
                  least 18 years of age, or the legal age of majority in your
                  jurisdiction, and possess the legal authority, right and
                  freedom to enter into these Terms as a binding agreement. You
                  are not allowed to use this website and/or receive services if
                  doing so is prohibited in your country or under any law or
                  regulation applicable to you.
                </p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">Offering to the customers</p>
                <p className="privcytext ms-sm-4">
                  When buying an item, you agree that: (i) you are responsible
                  for reading the full item listing before making a commitment
                  to buy it: (ii) you enter into a legally binding contract to
                  purchase an item when you commit to buy an item and you
                  complete the check-out payment process. The prices we charge
                  for using our services / for our products are listed on the
                  website. We reserve the right to change our prices for
                  products displayed at any time and to correct pricing errors
                  that may inadvertently occur. Additional information about
                  pricing and sales tax is available on the payments page. “The
                  fee for the services and any other charges you may incur in
                  connection with your use of the service, such as taxes and
                  possible transaction fees, will be charged to you".
                </p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">Cancellation of Order</p>
                <p className="privcytext ms-sm-4">
                  Cancellation of an order is not possible once the payment has
                  been made. No refunds will be given except in the event of
                  cancellation or non-performance of service by CMJ Industries.
                </p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">
                  Ownership of intellectual property, copyrights, and logos
                </p>
                <p className="privcytext ms-sm-4">
                  The Service and all materials therein or transferred thereby,
                  including, without limitation, software, images, text,
                  graphics, logos, patents, trademarks, service marks,
                  copyrights, photographs, audio, videos, music, and all
                  Intellectual Property Rights related thereto, are the
                  exclusive property of CMJ Industries. Except as explicitly
                  provided herein, nothing in these Terms shall be deemed to
                  create a license in or under any such Intellectual Property
                  Rights, and you agree not to sell, license, rent, modify,
                  distribute, copy, reproduce, transmit, publicly display,
                  publicly perform, publish, adapt, edit or create derivative
                  works thereof. You recognize and agree that by uploading any
                  content (including, but not limited to designs, images,
                  animations, videos, audio files, fonts, logos, illustrations,
                  compositions, artworks, interfaces, text and literary works)
                  through any means to the website, you confirm that you own all
                  the relevant rights or received the appropriate license to
                  upload/transfer/send the content. You agree and consent that
                  the uploaded/transferred content may be publicly displayed on
                  the website.
                </p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">
                  Right to suspend or cancel the user account
                </p>
                <p className="privcytext ms-sm-4">
                  We may permanently or temporarily terminate or suspend your
                  access to the Service without notice and liability for any
                  reason, including if in our sole determination you violate any
                  provision of these Terms or any applicable law or regulations.
                  You may discontinue use and request to cancel your account
                  and/or any services at any time. Notwithstanding anything to
                  the contrary in the foregoing, with respect to
                  automatically-renewed subscriptions to paid services, such
                  subscriptions will be discontinued only upon the expiration of
                  the respective period for which you have already made payment.
                </p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">Indemnification</p>
                <p className="privcytext ms-sm-4">
                  You agree to indemnify and hold CMJ Industries harmless from
                  any demands, loss, liability, claims or expenses (including
                  attorneys’ fees), made against them by any third party due to.
                </p>

                <h1 className="bigtextt">Terms And Condition</h1>
              </div> */}
            </div>
          </div>

          <div className="details-section">
            <Customer_Support_Details />
          </div>
        </Container>
      </section>
    </>
  );
}

export default Terms_And_Condition;
