import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getBanners = async () => {
  try {
    return await getData(`/without-login/home/banner`);
  } catch (error) {
    console.log(error);
  }
};

export const getHomeSideBanner = async () => {
  try {
    return await getData(`/without-login/home/home-side-banner`);
  } catch (error) {
    console.log(error);
  }
};

export const getWhyChooseUs = async () => {
  try {
    return await getData(`/without-login/why/choose-us`);
  } catch (error) {
    console.log(error);
  }
};

export const getProductCategory = async (category_id = "") => {
  try {
    return await getData(
      `/without-login/home/product-category?category_id=${category_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getSale = async () => {
  try {
    return await getData(`/without-login/home/sale`);
  } catch (error) {
    console.log(error);
  }
};

export const getWhatPeopleSay = async (category = null) => {
  try {
    return await getData(`/without-login/home/what-people-say`);
  } catch (error) {
    console.log(error);
  }
};

export const getTopPicksProducts = async () => {
  try {
    return await getData(`/without-login/home/top-picks`);
  } catch (error) {
    console.log(error);
  }
};

export const getVochers = async () => {
  try {
    return await getData(`/without-login/home/coupons`);
  } catch (error) {
    console.log(error);
  }
};

export const getallOrderOrderedStatus = async () => {
  try {
    return await getData(`/without-login/all-order-ordered-status`);
  } catch (error) {
    console.log(error);
  }
};

export const getTrackOrders = async (invoice_number) => {
  try {
    return await getData(
      `/without-login/home/track-order?invoice_number=${invoice_number}`
    );
  } catch (error) {
    console.log(error);
  }
};
// ----------------------------------------------------------------

// export const getRandomCategory = async () => {
//   try {
//     return await getData(`/without-login/home/random-category`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const getTrendingProduct = async (category = null) => {
//   try {
//     return await getData(
//       `/without-login/home/trending-product?category_id=${category}&page=1`
//     );
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const getBestEssential = async (category = null) => {
//   try {
//     return await getData(`/without-login/home/best-essential`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const getWhoWhereAre = async (category = null) => {
//   try {
//     return await getData(`/without-login/about-us/who-where-are`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const Country = async () => {
//   try {
//     return await getData(`/without-login/home/get-country`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const State = async (id) => {
//   try {
//     return await getData(`/without-login/home/get-state/${id}`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const City = async (id) => {
//   try {
//     return await getData(`/without-login/home/get-city/${id}`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const Cities = async () => {
//   try {
//     return await getData(`/without-login/allcity`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const Pincode = async (id) => {
//   try {
//     return await getData(`/without-login/home/get-pincode/${id}`);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const Coupons = async () => {
//   try {
//     return await getData(`/without-login/home/coupons`);
//   } catch (error) {
//     console.log(error);
//   }
// };
