import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Quantity_discount_modal.css";
import { getProductDetail } from "../../../../utils/apis/Product/Product";

const Quantity_discount_modal = (props) => {
  const [data, setData] = useState({});
  const getData = async (id) => {
    const res = await getProductDetail(id);
    setData(res.data);
  };

  useEffect(() => {
    if (props.show) {
      getData(props.show);
    }
  }, [props.show]);

  // console.log(data?.product_min_order_qties);
  // console.log(props.show);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Quantity_discount_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Quantity Discounts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mod-body">
            <h5 className="tttt mb-3">Percent of business price</h5>

            {data?.product_min_order_qties ? (
              data?.product_min_order_qties?.map((item, index) => (
                <div className="d-flex quantdiv" key={index}>
                  <div className="">
                    <p>Minimum</p>
                    <p>Quantity</p>
                  </div>
                  <div className="quabox">
                    <p>{item?.quantity}</p>
                  </div>
                  <div className="my-auto">
                    <p>get</p>
                  </div>
                  <div className="quabox">
                    <p>{item?.percentage}</p>
                  </div>
                  <div className="">
                    <p>%</p>
                    <p>off</p>
                  </div>
                </div>
              ))
            ) : (
              <h5>No Quantity Discount </h5>
            )}
            {/* <div className="d-flex quantdiv">
              <div className="">
                <p>Minimum</p>
                <p>Quantity</p>
              </div>
              <div className="quabox">
                <p>2</p>
              </div>
              <div className="my-auto">
                <p>get</p>
              </div>
              <div className="quabox">
                <p>4.00</p>
              </div>
              <div className="">
                <p>%</p>
                <p>off</p>
              </div>
            </div>

            <div className="d-flex quantdiv">
              <div className="">
                <p>Minimum</p>
                <p>Quantity</p>
              </div>
              <div className="quabox">
                <p>2</p>
              </div>
              <div className="my-auto">
                <p>get</p>
              </div>
              <div className="quabox">
                <p>4.00</p>
              </div>
              <div className="">
                <p>%</p>
                <p>off</p>
              </div>
            </div>

            <div className="d-flex quantdiv">
              <div className="">
                <p>Minimum</p>
                <p>Quantity</p>
              </div>
              <div className="quabox">
                <p>2</p>
              </div>
              <div className="my-auto">
                <p>get</p>
              </div>
              <div className="quabox">
                <p>4.00</p>
              </div>
              <div className="">
                <p>%</p>
                <p>off</p>
              </div>
            </div> */}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Quantity_discount_modal;
