import React from "react";
import Slider from "react-slick";

import "./verticalsliderr.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Verticalslider = ({ data, IMG_URL }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,

    beforeChange: function(currentSlide, nextSlide) {
      // console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function(currentSlide) {
      // console.log("after change", currentSlide);
    },
    onSwipe: function(direction) {
      // console.log("Swipe direction:", direction);
    },
  };

  return (
    <div className="verticalsliderr">
      <div className="slider-container">
        <Slider {...settings}>
          {data?.map((item, index) => (
            <div key={index}>
              <div className="slideimdivvv">
                <img
                  className="imgg"
                  src={IMG_URL + item.image}
                  alt={`slide-${index}`}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Verticalslider;
