import React, { useContext, useEffect, useState } from "react";
import "./SaleIsLive.css";
import { Container } from "react-bootstrap";
import Countdown from "react-countdown";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import ProductCard from "../../CommonComponent/ProductCard/ProductCard";
// import CountUp from "react-countup";
import ProductCardSale from "../../CommonComponent/ProductCardSale/ProductCardSale";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import { getSale } from "../../../utils/apis/home";
import CountdownTimer from "../../CommonComponent/CountUp/CountUp";
import { product_coupon, UserType } from "../../../utils/common";
function SaleIsLive() {
  const product = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
  ];

  //   -------------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser, usertype } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getSale();
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="salelive_section">
        <Container>
          <div className="salelive_main_div">
            <div className="toptext_div ">
              <h2 className="title-main mb-3">
                <span className="main-title-border ">SALE IS LIVE</span> ON
              </h2>

              <CountdownTimer endDate={data?.Sale?.end_date} />

              {/*  */}

              {/* <div className="coundown_div mb-3">
                <div className="counter">
                  <div className="timer">
                    <CountUp start={0} end={0} duration={10} decimals={0} />
                    <CountUp start={0} end={0} duration={10} decimals={0} />
                  </div>
                  <div className="timer dotts">:</div>
                  <div className="timer">
                    <CountUp start={0} end={0} duration={10} decimals={0} />
                    <CountUp start={0} end={10} duration={10} decimals={0} />
                  </div>
                  <div className="timer dotts">:</div>
                  <div className="timer">
                    <CountUp start={0} end={0} duration={10} decimals={0} />
                    <CountUp start={0} end={60} duration={10} decimals={0} />
                  </div>
                </div>
              </div> */}
            </div>

            <div className="swipe_div_main">
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  486: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1400: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                  1900: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                }}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.products?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <ProductCardSale
                      average_rating={item.average_rating}
                      id={item.id}
                      image={item.image}
                      title={item.name}
                      discount_percentage={item.discount_percentage}
                      short_description={item?.short_description}
                      price={
                        usertype == UserType.B2BCustomer
                          ? item?.btb_price
                          : item?.btc_price
                      }
                      mrp={item?.mrp}
                      save={
                        product_coupon.Percentage ===
                        item?.product_cuppon?.coupon_type
                          ? item?.product_cuppon?.discount + " %"
                          : "₹ " + item?.product_cuppon?.total_amount
                      }
                      // image={item.image}
                      // title={item.title}
                      // size={item.size}
                      // saleprice={item.saleprice}
                      // realprice={item.realprice}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default SaleIsLive;
