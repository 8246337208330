import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Container } from "react-bootstrap";
import { Policy } from "../../utils/apis/master/Master";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router-dom";

function CancelPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Cancellation Policy", link: "" },
    ,
  ]);

  // ----------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await Policy("cancellation");
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="privacy_section">
        <Container>
          <div>
            <Breadcrumbs items={breadcrumbItems} />
          </div>
          <div className="Privacy_main">
            <div className="privacybanner_div ">
              <img
                className="privacybanner"
                src={
                  process.env.PUBLIC_URL + "/assests/Banner/cancellbanner.png"
                }
              />
              <div className="banoverlays">
                <p className="bannertitle">Cancellation Policy</p>
              </div>
            </div>
            <div className="bannertext_main_div">
              <div className="bannertext_div mb-3">
                <p className="text-p mb-0">{data?.short_description}</p>
              </div>

              <div className="bannersubtext_div mb-3">
                {" "}
                {htmlToReactParser.parse(data?.description) || ""}
                <h1 className="bigtextt">Cancellation Policy</h1>
              </div>
              {/* <div className="bannertext_div mb-3">
                <p className="text-p mb-0">
                  After payment is made and the order is scheduled or shipped
                  successfully, order cancellation is not allowed for any
                  reason. Please think carefully before placing an order, as the
                  option to cancel is not available on the customer side.
                </p>
              </div>

              <div className="bannersubtext_div mb-3">
                <p className="privecy_subtitle">
                  Why is the cancel order option not available on the customer
                  side?
                </p>
                <p className="privcytext">
                  1. We prioritize serving genuine customers and maintaining a
                  secure shopping environment. By limiting cancellations, we can
                  better focus on fulfilling orders and providing excellent
                  customer service.
                </p>
                <p className="privcytext">
                  2. We want customers to trust us and our ecommerce website.
                  We're committed to building a long-term, honest business, and
                  our customers should feel secure and confident in their
                  transactions.
                </p>

                <p className="privcytext">
                  3. We aim to avoid unnecessary complications and losses due to
                  frequent order cancellations.{" "}
                </p>
                <p className="privcytext">
                  4. We strive to provide quick and efficient shipping, and
                  cancelling orders can disrupt our logistics and shipping
                  schedules.{" "}
                </p>
                <p className="privcytext">
                  5. We aim to maintain a streamlined and hassle-free shopping
                  experience for our genuine customers.
                </p>

                <h1 className="bigtextt">Cancellation Policy</h1>
              </div> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default CancelPolicy;
