import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Common_Button from "../../CommonComponent/Button_Common/Button_Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import Bottom_Successfull_Modal from "../../CommonComponent/Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { useForm } from "react-hook-form";
import { ForgetPassword } from "../../../utils/apis/login/Login";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
function Set_New_Password(props) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisibleone, setPasswordVisibleone] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const [
    modalBottomSuccessfullShow,
    setModalBottomSuccessModalFalse,
  ] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordVisibilityone = () => {
    setPasswordVisibleone(!passwordVisibleone);
  };

  const handleResetPasswordSubmit = () => {
    setModalBottomSuccessModalFalse(true);
    setTimeout(() => {
      setModalBottomSuccessModalFalse(false);
      navigate("/login");
    }, 3000);
  };

  //   ------------------------------------------------------------------------------------------------
  const { email_or_contact } = useParams();
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      if (password === passwordAgain) {
        // console.log(data);
        const sendData = {
          new_password: data.new_password,
          email_or_contact: email_or_contact,
        };
        // console.log(sendData);
        const res = await ForgetPassword(sendData);
        if (res?.success) {
          reset();
          reset();
          clearErrors();
          handleResetPasswordSubmit();
        } else {
          if (res?.message) {
            setError("re_password", {
              message: res?.message,
            });
          }
        }
      } else {
        setError("re_password", { message: "Password Does not match" });
      }
      //   console.log(data);
      //   const res = await ChangePassword(data);
      //   reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="Modal-holder">
        <div className="LogIn">
          <div className="row bg-color me-0 ms-0 justify-content-center LogIn-holder">
            <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-8">
              <div className="log_in_form">
                <div className="Main-starting">
                  <div className="login-box-button">
                    <p>Set New Password</p>
                  </div>

                  <div className="text-holder">
                    <p className="note-text sub-title">
                      Your new password must be Different from the previously
                      used password.
                    </p>
                  </div>

                  <Form>
                    <Form.Group className=" mb-3" controlId="">
                      <Form.Label className="small-text ">
                        Enter your password
                      </Form.Label>
                      <div className="password-field-holder">
                        <Form.Control
                          type={passwordVisibleone ? "text" : "password"}
                          placeholder="Password"
                          name="new_password"
                          {...register("new_password", {
                            required: "Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "Password must be at least 8 characters long",
                            },
                            pattern: {
                              value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                              message:
                                "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.new_password,
                            // "is-valid": getValues("new_password"),
                          })}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div
                          className="eye-icon-holder"
                          onClick={togglePasswordVisibilityone}
                        >
                          <FontAwesomeIcon
                            className="eye-icon"
                            icon={passwordVisibleone ? faEye : faEyeSlash}
                          />
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className=" mb-3" controlId="">
                      <Form.Label className="small-text ">
                        confirm password
                      </Form.Label>
                      <div className="password-field-holder">
                        <Form.Control
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Re-Enter Password*"
                          name="re_password"
                          {...register("re_password", {
                            required: "Re-Password is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.re_password,
                            "is-valid": getValues("re_password"),
                          })}
                          onChange={(e) => setPasswordAgain(e.target.value)}
                        />
                        <div
                          className="eye-icon-holder"
                          onClick={togglePasswordVisibility}
                        >
                          <FontAwesomeIcon
                            className="eye-icon"
                            icon={passwordVisible ? faEye : faEyeSlash}
                          />
                        </div>
                      </div>
                    </Form.Group>

                    {password && (
                      <PasswordChecklist
                        rules={[
                          "minLength",
                          "specialChar",
                          "number",
                          "capital",
                          "match",
                        ]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(isValid) => {}}
                        style={{
                          fontSize: "10px",
                          padding: "4px",
                          margin: "0",
                        }}
                      />
                    )}

                    <div className="btn-holder">
                      <Common_Button
                        className="button button--webcoderskull button--border-thick button--text-medium login-btn  reset sub-title orange"
                        text="Reset Password"
                        // onClick={handleResetPasswordSubmit}
                        onClick={handleSubmit(onSubmit)}
                      />
                    </div>
                  </Form>

                  <Link to="/">
                    <div className="back_to_span">
                      <p>Back to Home {">"}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom_Successfull_Modal
        show={modalBottomSuccessfullShow}
        onHide={() => {
          setModalBottomSuccessModalFalse(false);
        }}
        icon={faCircleCheck}
        text={"Password Reset Successfully!"}
      />
    </>
  );
}

export default Set_New_Password;
