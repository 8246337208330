import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Voucher.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../../components/CommonComponent/Breadcrumbs/Breadcrumbs";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../utils/context";
import { getVochers } from "../../utils/apis/home";
import CountdownTimer from "../CommonComponent/CountUp/CountUp";
import CoupponCountdownTimer from "../CommonComponent/CountUp/CoupponCountUp";
import { cuppon_type } from "../../utils/common";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SuccessNotify } from "../../utils/tosters";
const Voucher = () => {
  const [time, setTime] = useState({
    months: "00",
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const targetDate = new Date("2025-01-01T00:00:00");

    const updateCountdown = () => {
      const now = new Date();
      const timeDifference = targetDate - now;

      if (timeDifference <= 0) {
        setTime({
          months: "00",
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
        return;
      }

      const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
      const days = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
      );
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setTime({
        months: String(months).padStart(2, "0"),
        days: String(days).padStart(2, "0"),
        hours: String(hours).padStart(2, "0"),
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
      });
    };

    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "" },
    { text: "Account", link: "" },
    { text: "Vouchers", link: "", className: "active" },
  ]);

  //   ------------------------------------------------------------------------
  // /without-login/home/coupons
  const { usertype, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const getData = async () => {
    const res = await getVochers();
    if (res.success) {
      setData(res?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Inside your React component
  const [isCopied, setIsCopied] = useState(false);

  const onCopyHandler = () => {
    setIsCopied(true);
    // SuccessNotify("Copied to clipboard");
    setTimeout(() => setIsCopied(false), 2500); // Hide the success message after 2.5 seconds
  };

  /* {isCopied && (
      <div className="alert-success">
        Copied to clipboard!
      </div>
    )} */

  return (
    <>
      <section className="Voucher">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="Voucher-banner">
            <img
              src={process.env.PUBLIC_URL + "/assests/voucher/voucher.png"}
              className="voucher-banner"
            />
            <img
              src={process.env.PUBLIC_URL + "/assests/voucher/voucher2.png"}
              className="voucher-banner2"
            />
          </div>

          <div className="voucher-cards">
            <Row className="justify-content-center">
              {data?.map((item, index) => (
                <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="Main-voucher">
                    <div className="card-sale">
                      <div className="coupon-heading">
                        {cuppon_type.percentage == item.type ? (
                          <p className="sub-title">
                            <span className="sub-title off">
                              {item.discount}% Off
                            </span>
                          </p>
                        ) : (
                          <p className="sub-title">
                            <span className="sub-title off">
                              {" "}
                              ₹{item.total_amount} Off
                            </span>
                          </p>
                        )}
                        {/* <p className="sub-title">
                          <span className="sub-title off">5% Off</span> Upto
                          ₹200
                        </p> */}
                      </div>
                      <div className="image-main">
                        <img
                          src={IMG_URL + item?.image}
                          className="coupon-img"
                        />
                      </div>
                      <div className="code-main">
                        <div className="code">
                          <p className="small-text">Code</p>
                          <div className="copy">
                            <p className="small-text code-coupn">{item.code}</p>

                            <CopyToClipboard
                              text={item.code}
                              onCopy={onCopyHandler}
                            >
                              <FontAwesomeIcon
                                icon={faCopy}
                                className="icon "
                              />
                            </CopyToClipboard>
                          </div>
                        </div>
                        <div className="code2">
                          <p className="small-text">Expiry</p>
                          <CoupponCountdownTimer endDate={item?.e_date} />
                          {/* <CountdownTimer endDate={item?.e_date} /> */}
                          {/* <div className="countdown">
                            <div className="main-time me-2">
                              <span id="hours" className="small-text">
                                {time.hours}
                              </span>
                              <div className="label small-text">h:</div>
                            </div>
                            <div className="main-time me-2">
                              <span id="minutes" className="small-text">
                                {time.minutes}
                              </span>
                              <div className="label small-text">m:</div>
                            </div>
                            <div className="main-time">
                              <span id="seconds" className="small-text">
                                {time.seconds}
                              </span>
                              <div className="label small-text">s</div>
                            </div>
                          </div> */}
                        </div>

                        <div className="white-strip"></div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              {/* <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="Main-voucher">
                  <div className="card-sale">
                    <div className="coupon-heading">
                      <p className="sub-title">
                        <span className="sub-title off">5% Off</span> Upto ₹200
                      </p>
                    </div>
                    <div className="image-main">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/voucher/Subtraction.png"
                        }
                        className="coupon-img"
                      />
                    </div>
                    <div className="code-main">
                      <div className="code">
                        <p className="small-text">Code</p>
                        <div className="copy">
                          <p className="small-text code-coupn">TRYI0WJ098</p>
                          <FontAwesomeIcon icon={faCopy} className="icon " />
                        </div>
                      </div>
                      <div className="code2">
                        <p className="small-text">Expiry</p>
                        <div className="countdown">
                          <div className="main-time me-2">
                            <span id="hours" className="small-text">
                              {time.hours}
                            </span>
                            <div className="label small-text">h:</div>
                          </div>
                          <div className="main-time me-2">
                            <span id="minutes" className="small-text">
                              {time.minutes}
                            </span>
                            <div className="label small-text">m:</div>
                          </div>
                          <div className="main-time">
                            <span id="seconds" className="small-text">
                              {time.seconds}
                            </span>
                            <div className="label small-text">s</div>
                          </div>
                        </div>
                      </div>

                      <div className="white-strip"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="Main-voucher">
                  <div className="card-sale">
                    <div className="coupon-heading">
                      <p className="sub-title">
                        <span className="sub-title off">5% Off</span> Upto ₹200
                      </p>
                    </div>
                    <div className="image-main">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/voucher/Subtraction.png"
                        }
                        className="coupon-img"
                      />
                    </div>
                    <div className="code-main">
                      <div className="code">
                        <p className="small-text">Code</p>
                        <div className="copy">
                          <p className="small-text">TRYI0WJ098</p>
                          <FontAwesomeIcon icon={faCopy} className="icon " />
                        </div>
                      </div>
                      <div className="code2">
                        <p className="small-text">Expiry</p>
                        <div className="countdown">
                          <div className="main-time me-2">
                            <span id="hours" className="small-text">
                              {time.hours}
                            </span>
                            <div className="label small-text">h:</div>
                          </div>
                          <div className="main-time me-2">
                            <span id="minutes" className="small-text">
                              {time.minutes}
                            </span>
                            <div className="label small-text">m:</div>
                          </div>
                          <div className="main-time">
                            <span id="seconds" className="small-text">
                              {time.seconds}
                            </span>
                            <div className="label small-text">s</div>
                          </div>
                        </div>
                      </div>

                      <div className="white-strip"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="Main-voucher">
                  <div className="card-sale">
                    <div className="coupon-heading">
                      <p className="sub-title">
                        <span className="sub-title off">5% Off</span> Upto ₹200
                      </p>
                    </div>
                    <div className="image-main">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/voucher/Subtraction.png"
                        }
                        className="coupon-img"
                      />
                    </div>
                    <div className="code-main">
                      <div className="code">
                        <p className="small-text">Code</p>
                        <div className="copy">
                          <p className="small-text">TRYI0WJ098</p>
                          <FontAwesomeIcon icon={faCopy} className="icon " />
                        </div>
                      </div>
                      <div className="code2">
                        <p className="small-text">Expiry</p>
                        <div className="countdown">
                          <div className="main-time me-2">
                            <span id="hours" className="small-text">
                              {time.hours}
                            </span>
                            <div className="label small-text">h:</div>
                          </div>
                          <div className="main-time me-2">
                            <span id="minutes" className="small-text">
                              {time.minutes}
                            </span>
                            <div className="label small-text">m:</div>
                          </div>
                          <div className="main-time">
                            <span id="seconds" className="small-text">
                              {time.seconds}
                            </span>
                            <div className="label small-text">s</div>
                          </div>
                        </div>
                      </div>

                      <div className="white-strip"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="Main-voucher">
                  <div className="card-sale">
                    <div className="coupon-heading">
                      <p className="sub-title">
                        <span className="sub-title off">5% Off</span> Upto ₹200
                      </p>
                    </div>
                    <div className="image-main">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/voucher/Subtraction.png"
                        }
                        className="coupon-img"
                      />
                    </div>
                    <div className="code-main">
                      <div className="code">
                        <p className="small-text">Code</p>
                        <div className="copy">
                          <p className="small-text">TRYI0WJ098</p>
                          <FontAwesomeIcon icon={faCopy} className="icon " />
                        </div>
                      </div>
                      <div className="code2">
                        <p className="small-text">Expiry</p>
                        <div className="countdown">
                          <div className="main-time me-2">
                            <span id="hours" className="small-text">
                              {time.hours}
                            </span>
                            <div className="label small-text">h:</div>
                          </div>
                          <div className="main-time me-2">
                            <span id="minutes" className="small-text">
                              {time.minutes}
                            </span>
                            <div className="label small-text">m:</div>
                          </div>
                          <div className="main-time">
                            <span id="seconds" className="small-text">
                              {time.seconds}
                            </span>
                            <div className="label small-text">s</div>
                          </div>
                        </div>
                      </div>

                      <div className="white-strip"></div>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Voucher;
