import React, { useContext, useEffect, useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { AppSetup, SocialMedia } from "../../utils/apis/common/Common";
import { Context } from "../../utils/context";
const Footer = () => {
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const getData = async () => {
    {
      const res = await AppSetup();
      setData(res.data);
    }

    {
      const res = await SocialMedia();
      setData2(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="Footer">
        <Row className="mx-auto">
          <Col md={11} className="mx-auto">
            <div className="logo-img-and-list-holder">
              <div className="logo-footer">
                <img
                  src={process.env.PUBLIC_URL + "/assets/Image/footer.png"}
                  className="logo"
                />
              </div>

              <div className="footer-list-holder">
                <Row className="justify-content-center">
                  <Col
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={6}
                    sm={6}
                    xs={12}
                    className="footer-list"
                  >
                    <div className="content">
                      <p className="footer-text">Contact Us</p>
                      <p className="small-text mb-4">
                        Email:<br></br> {data?.email}
                      </p>
                      <p className="small-text mb-4">
                        Phone: +91 {data?.contact_no}
                      </p>
                      <p className="small-text mb-4">
                        Address: {data?.address}
                      </p>
                    </div>
                  </Col>
                  <Col
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={6}
                    sm={6}
                    xs={12}
                    className="footer-list main1"
                  >
                    <div className="content ">
                      <p className="footer-text">Information</p>
                      <Link to={"/cmj-industries"}>
                        <p className="small-text">Why CMJ Industries?</p>
                      </Link>
                      <Link to={"/b2bbulkorder"}>
                        <p className="small-text">B2B Bulk Order</p>
                      </Link>
                      <Link to={"/hotdeals"}>
                        <p className="small-text">Hot Deals</p>
                      </Link>
                      <Link to={"/terms-and-condition"}>
                        <p className="small-text">Terms And Condition</p>
                      </Link>
                      <Link to={"/security-trust"}>
                        <p className="small-text">Security Trust</p>
                      </Link>
                      <Link to={"/payment-method"}>
                        <p className="small-text">Payment Method</p>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    xxl={3}
                    xl={3}
                    lg={3}
                    md={12}
                    sm={12}
                    xs={12}
                    className="footer-list order-lg-0 order-5"
                  >
                    <div className="logo-text">
                      <div className="logo-content">
                        <h1 className="cmj-text">CMJ</h1>
                        <h1 className="cmj-main ">Industries Pune</h1>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={6}
                    sm={6}
                    xs={12}
                    className="footer-list"
                  >
                    <div className="content">
                      <p className="footer-text">Customer Service</p>
                      <Link to={"/contact-us"}>
                        <p className="small-text">Contact Us</p>
                      </Link>
                      <Link to={"/faq"}>
                        <p className="small-text">FAQ</p>
                      </Link>
                      <Link to={"/shippingpolicy"}>
                        <p className="small-text">Shipping Policy</p>
                      </Link>
                      <Link to={"/return&exchangepolicy"}>
                        <p className="small-text">Return And Exchange Policy</p>
                      </Link>
                      <Link to={"/privacypolicy"}>
                        <p className="small-text">Privacy Policy</p>
                      </Link>
                      <Link to={"/Cancellationpolicy"}>
                        <p className="small-text">Cancellation Policy</p>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={6}
                    sm={6}
                    xs={12}
                    className="footer-list footer-last-col"
                  >
                    <div className="content">
                      <p className="footer-text">Follow Us</p>

                      {data2?.map((val, index) => (
                        <div className="icon">
                          <img
                            src={IMG_URL + val?.logo}
                            className="icon-footer"
                          />
                          <a href={val?.link} target="_blank">
                            <p className="small-text main">{val?.name}</p>
                          </a>
                        </div>
                      ))}

                      {/* <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assests/logo/facebook.png"
                          }
                          className="icon-footer"
                        />
                        <p className="small-text main">Facebook</p>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assests/logo/twitter.png"
                          }
                          className="icon-footer"
                        />
                        <p className="small-text main">Twitter</p>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assests/logo/linkedin.png"
                          }
                          className="icon-footer"
                        />
                        <p className="small-text main">Linkdin</p>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assests/logo/youtube.png"
                          }
                          className="icon-footer"
                        />
                        <p className="small-text main">Youtube</p>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assests/logo/instagram.png"
                          }
                          className="icon-footer"
                        />
                        <p className="small-text main">Instagram</p>
                      </div> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>

        <div className="note">
          <p className="note-text">
            Copyright ©2024 All Rights Reserved. Developed by Profcyma.
          </p>
        </div>
      </section>
    </>
  );
};

export default Footer;
