import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./AddedCard.css";
import LogOut from "../Common_Modal/LogOut/LogOut";
import { Context } from "../../../utils/context";
import {
  CartQuantity,
  CartStatus,
} from "../../../utils/apis/addToCart/addToCart";

const AddedCard = ({
  image,
  name,
  short_description,
  price,
  isSelected,
  onSelect,
  type,
  min_quantity,
  moq_id,
  mrp,
  box_unit,
  discount_percentage,
  ...props
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const [count, setCount] = useState(0);

  // const increaseCount = () => {
  //   setCount((prevCount) => prevCount + 1);
  // };

  // const decreaseCount = () => {
  //   setCount((prevCount) => Math.max(prevCount - 1, 0));
  // };
  const [modalShow, setModalShow] = React.useState(false);

  // ----------------------------------------------------------------
  const { signin, setCart, IMG_URL } = useContext(Context);
  let { quantity, id } = props;

  useEffect(() => {
    setNumber(quantity);
  }, [quantity]);

  const [number, setNumber] = useState(0);
  const handleMinusClick = async () => {
    if (number > 0) {
      await CartQuantity(id, "minus", signin, setCart);
      setNumber(number - 1);
      if (props.updateQuantity) {
        props.updateQuantity(props.index, number - 1);
      }
    }
  };

  const handlePlusClick = async () => {
    await CartQuantity(id, "add", signin, setCart);
    setNumber(number + 1);
    if (props.updateQuantity) {
      props.updateQuantity(props.index, number + 1);
    }
  };
  return (
    <div className="Deatils-address">
      <Form.Check
        type="checkbox"
        checked={isSelected}
        onChange={(e) => {
          CartStatus(
            id,
            {
              status: e.target.checked,
            },
            signin,
            setCart
          );
        }}
      />
      <div className="OrderDetails">
        <div className="Details">
          <Row>
            <Col xxl={3} xl={3} lg={3} md={3} xs={12} sm={12}>
              <div className="main-cart">
                <div className="image-product">
                  <img
                    src={signin ? IMG_URL + image : image}
                    alt="product"
                    className="payment-logo"
                  />
                </div>
              </div>
            </Col>
            <Col xxl={7} xl={8} lg={7} md={7} xs={12} sm={12}>
              <div className="content">
                <p className="commmontext">
                  {name} {type === "bulk" ? "{Bulk Order}" : "{Single}"}
                </p>
                <p className="small-text">{short_description}</p>

                {discount_percentage && (
                  <p className="small-text">
                    Sale Discount : {discount_percentage}%
                  </p>
                )}

                <p className="small-text">Number of Items : {box_unit}</p>
                <p className="small-text">Min QTY : {min_quantity}</p>
                <div className="Quantity">
                  <p className="small-text">QTY</p>
                  {/* <div className="quantity-no sub-title">{quantity}</div> */}

                  <span className="conutssdiv me-3">
                    {!moq_id && (
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="ficnn px-1"
                        onClick={() => {
                          !(min_quantity == quantity) && handleMinusClick();
                        }}
                      />
                    )}
                    <span className="px-1">{quantity}</span>
                    {!moq_id && (
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="ficnn px-1"
                        onClick={handlePlusClick}
                      />
                    )}
                  </span>
                </div>
                <div className="d-flex">
                  <p className="price commmontext mrptextt">
                    MRP : ₹ <span>{mrp}</span>
                  </p>
                  <p className="price commmontext">Price : ₹ {price}</p>
                </div>
              </div>
            </Col>
            <Col xxl={2} xl={1} lg={2} md={1} xs={12} sm={12}>
              <div className="Delete-order" onClick={() => setModalShow(true)}>
                <div className="delete-main">
                  <FontAwesomeIcon icon={faTrash} className="icon" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <LogOut id={id} show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default AddedCard;
