import React, { useContext, useEffect, useState } from "react";
import "./PrivacyPolicy.css";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import { Policy } from "../../utils/apis/master/Master";
function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Privacy Policy", link: "" },
    ,
  ]);

  // ----------------------------------------------------------------------------------
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await Policy("private");
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="privacy_section">
        <Container>
          <div>
            <Breadcrumbs items={breadcrumbItems} />
          </div>
          <div className="Privacy_main">
            <div className="privacybanner_div ">
              <img
                className="privacybanner"
                src={process.env.PUBLIC_URL + "/assests/Banner/pvcybnnr.png"}
              />
              <div className="banoverlays">
                <p className="bannertitle">Privacy Policy</p>
              </div>
            </div>
            <div className="bannertext_main_div">
              <div className="bannertext_div mb-3">
                <p className="text-p mb-0">{data?.short_description}</p>
              </div>

              <div className="bannersubtext_div mb-3">
                {htmlToReactParser.parse(data?.description) || ""}
                <h1 className="bigtextt">Privacy Policy</h1>
              </div>

              {/* <div className="bannertext_div mb-3">
                <p className="text-p mb-0">
                  CMJ Industries is committed to protecting the privacy of our
                  customers and users of our website. This privacy policy
                  explains how we collect, use, and disclose personal
                  information from our website users.
                </p>
              </div> */}

              {/* <div className="bannersubtext_div mb-3">
                <p className="privecy_subtitle">01. Information We Collect</p>
                <p className="privcytext">
                  - Personal information such as name, email address, phone
                  number, and shipping address{" "}
                </p>
                <p className="privcytext">- IP address and browser type</p>
                <p className="privcytext">
                  - Order information such as order number, date, and total
                </p>
                <p className="privcytext">
                  - Payment information such as payment method and payment date
                </p>
                <p className="privcytext">- IP address and browser type</p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">
                  02. How We Use Your Information
                </p>
                <p className="privcytext">
                  - To process and fulfill your orders{" "}
                </p>
                <p className="privcytext">
                  - To communicate with you about your orders and our services{" "}
                </p>
                <p className="privcytext">
                  - To provide customer support and respond to your inquiries{" "}
                </p>
                <p className="privcytext">
                  {" "}
                  - To improve our website and services{" "}
                </p>
                <p className="privcytext">
                  - To comply with legal and regulatory requirements
                </p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">
                  03. How We Share Your Information
                </p>
                <p className="privcytext">
                  - With our third-party service providers who perform services
                  on our behalf{" "}
                </p>
                <p className="privcytext">
                  {" "}
                  - With our payment processors to process payments{" "}
                </p>
                <p className="privcytext">
                  {" "}
                  - With our shipping providers to ship your orders{" "}
                </p>
                <p className="privcytext">
                  {" "}
                  - With law enforcement agencies or courts to comply with legal
                  requirements
                </p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">04. Data Security</p>
                <p className="privcytext">
                  - We use industry-standard encryption to protect your personal
                  information
                </p>
                <p className="privcytext">
                  {" "}
                  - We use secure servers to store your personal information{" "}
                </p>
                <p className="privcytext">
                  - We restrict access to your personal information to
                  authorized personnel only
                </p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">05. Data Retention</p>
                <p className="privcytext">
                  - We retain your personal information for as long as necessary
                  to provide our services and comply with legal requirements{" "}
                </p>
                <p className="privcytext">
                  - We delete your personal information when it is no longer
                  necessary
                </p>

                <div className="borderss my-3"></div>

                <p className="privecy_subtitle">06. Your Rights</p>
                <p className="privcytext">
                  - You have the right to request deletion of your personal
                  information{" "}
                </p>
                <p className="privcytext">
                  - You have the right to request deletion of your personal
                  information{" "}
                </p>
                <p className="privcytext">
                  - You have the right to request deletion of your personal
                  information{" "}
                </p>
              </div> */}

              {/* <div className="bannersubtext_div mb-3">
                <p className="privecy_subtitle">Contact Us</p>
                <p className="privcytext">
                  If you have any questions or concerns about this privacy
                  policy, please contact us at: mailto:cmjindustries13@gmail.com
                </p>

                <p className="privecy_subtitle">Effective Date</p>
                <p className="privcytext">
                  This privacy policy is effective as of 26-05-2024 and may be
                  updated from time to time.
                </p>

                <p className="privecy_subtitle">Additional Information</p>
                <p className="privecy_subtitle  ms-2">Cookies</p>
                <p className="privcytext">
                  -We use cookies to improve your browsing experience and to
                  collect information about your usage of our website{" "}
                </p>
                <p className="privcytext">
                  - You can adjust your browser settings to accept or reject
                  cookies
                </p>
                <p className="privecy_subtitle ms-2">Third-Party Links </p>
                <p className="privcytext">
                  - Our website may contain links to third-party websites{" "}
                </p>
                <p className="privcytext">
                  - We are not responsible for the privacy practices of these
                  websites
                </p>

                <h1 className="bigtextt">Privacy Policy</h1>
              </div> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default PrivacyPolicy;
